.ticket_info_record {
  height: 100%;
}
.ticket_info_record .search-box {
  background-color: #fff;
  position: relative;
  padding: 20px 20px 0 20px;
  margin: 0;
}
.ticket_info_record .search-box .ant-calendar-picker,
.ticket_info_record .search-box .ant-calendar-picker-input,
.ticket_info_record .search-box .ant-input {
  border-color: #ECEEF7;
}
.ticket_info_record .search-box .ant-calendar-picker:hover,
.ticket_info_record .search-box .ant-calendar-picker-input:hover,
.ticket_info_record .search-box .ant-input:hover {
  border-color: #356CEA;
  border-right-width: 1px !important;
}
.ticket_info_record .search-box .ant-select-selection {
  border: 1px solid #ECEEF7;
}
.ticket_info_record .search-box .ant-select-selection:hover {
  border-color: #356CEA;
  border-right-width: 1px !important;
}
.ticket_info_record .search-box .switch-box {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 61px;
}
.ticket_info_record .search-box .switch-box p {
  height: 33px;
}
.ticket_info_record .search-box .switch-box span {
  font-size: 12px;
  color: #444444;
  font-weight: 400;
  line-height: 22px;
  height: 22px;
}
.ticket_info_record .search-box .switch-box button {
  margin-left: 10px;
}
.ticket_info_record .data-info {
  position: relative;
}
.ticket-info-drawer-box .detail_box {
  width: 460px;
  border-radius: 6px;
  border: 1px solid #F8FAFB;
}
.ticket-info-drawer-box .detail_box .item-title {
  width: 102px;
  font-size: 12px;
  color: #444444;
  font-weight: 500;
  padding-left: 20px;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #F8FAFB;
  line-height: 17px;
}
.ticket-info-drawer-box .detail_box .item-content {
  width: 356px;
  background: #FFFFFF;
  display: inline-block;
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 17px;
}
.ticket-info-drawer-box .detail_box .item-box {
  display: flex;
  justify-content: flex-start;
}
.ticket-info-drawer-box .detail_box .item-box p {
  margin-right: 20px;
}
.ticket-info-drawer-box .detail_box .item-box p span {
  margin-right: 20px;
}
.ticket-info-drawer-box .detail_box .item-box:last-child .item-title {
  padding-bottom: 20px;
}
.ticket-info-drawer-box .detail_box .item-box:last-child .item-content {
  padding-bottom: 20px;
}
