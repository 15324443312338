.bind_account .ant-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}
.bind_account .ant-row .ant-form-item-label {
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  height: 17px;
  line-height: 17px;
}
.bind_account .ant-row .ant-form-item-label label {
  font-size: 12px;
  color: #666666;
}
.bind_account .ant-row .ant-form-item-required::before {
  display: none;
}
.bind_account .ant-row .ant-form-item-control-wrapper {
  line-height: 34px;
}
.bind_account .ant-row .ant-form-item-control {
  line-height: 34px;
}
.bind_account .ant-form label {
  display: inline-block;
  width: 90px;
}
.bind_account .funcStyle {
  width: 460px;
  background: #F8FAFB;
  border: 1px solid #E9ECF0;
  padding: 0 13px;
  line-height: 32px;
  border-radius: 4px;
  font-size: 12px;
}
.bind_account .funcStyle .func-name {
  margin: 0 10px 10px 0 ;
  font-size: 12px;
  color: #8C8D8D;
}
