.right_account {
  height: 100%;
}
.right_account .breadcrumb {
  margin-left: 4px;
  margin-bottom: 10px;
  font-size: 12px;
}
.right_account .search-box {
  position: relative;
}
.right_account .create-role-btn {
  position: absolute;
  right: 0;
  bottom: 20px;
  width: 100px;
  height: 34px;
  border-radius: 4px;
}
.right_account .create-role-btn span {
  color: #fff;
}
.right_account .create-role-btn img {
  margin-right: 10px;
}
.right_account .container .divider {
  margin: 20px 0;
}
.right_account .container .open_vip {
  margin-bottom: 12px;
}
.right_account .forbid,
.right_account .normal {
  display: inline-table;
  vertical-align: middle;
  margin-right: 7px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.right_account .forbid {
  background-color: #D0021B;
}
.right_account .normal {
  background-color: #7CE008;
}
.right_account a {
  color: #1890ff;
}
.fixedTable .ant-table-fixed {
  table-layout: fixed;
}
.fixedTable .ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}
.fixedTable .func-name {
  margin: 0 10px 10px 0 ;
  font-size: 12px;
  color: #666666;
}
