.ygzfy {
  width: 240px;
  height: 30px;
  background: #ECEEF7;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 0 !important;
}
.ygzfy .blue {
  flex: 1;
  text-align: right;
}
.ygzfy .jxf-btn {
  width: 50px;
  height: 20px;
  background: #FFFFFF;
  border-radius: 10px;
  font-size: 12px;
  color: #356CEA;
  font-weight: 400;
  line-height: 20px;
  margin-left: 6px;
  cursor: pointer;
}
.ygzfy .jxf-btn img {
  margin-right: 4px;
}
.ant-table-wrapper .ant-table-tbody > tr:nth-child(2n-1) .ygfy-box {
  background: #FBFCFD;
}
.ant-table-wrapper .ant-table-tbody > tr:nth-child(2n) .ygfy-box {
  background: #fff;
}
.ygfy-box {
  width: 240px;
  padding: 8px 10px 9px 10px;
  border-radius: 0px 0px 4px 4px;
}
.ygfy-box p {
  margin-bottom: 6px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.textndswith {
  font-size: 12px;
}
.textndswith .swith {
  margin-left: 5px;
}
.fee-type-box {
  width: 460px;
  background: #F8FAFB;
  border-radius: 4px;
  padding: 20px;
}
.fee-type-box p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  font-size: 14px;
  color: #444444;
  font-weight: 500;
}
.fee-type-box p span {
  font-weight: 400;
}
.fee-type-box p:last-child {
  margin-bottom: 0;
}
