.address-select {
  display: flex;
  width: 100%;
  padding: 0 0 10px 0;
}
.address-select .mm {
  margin: 0 5px;
}
.address-select .ant-select {
  width: 150px;
}
.address-select .ant-select .ant-select-selection--single {
  width: 150px;
}
.reset-sb- {
  align-items: start !important;
}
.ticket-info-edit-box textarea.ant-input {
  width: 460px;
}
