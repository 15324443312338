.ghjg {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 102px;
  right: 20px;
}
.ghjg .mm {
  margin: 0 10px;
}
.project-top-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.project-top-box .txt {
  font-size: 20px;
  color: #444444;
  font-weight: 600;
  line-height: 28px;
}
.project-top-box button {
  width: 80px;
  height: 34px;
}
.drygry-modal .ant-modal-close {
  display: block;
}
.drygry-modal .ant-modal-confirm-btns button {
  display: none;
}
.drygry-modal .ant-modal-confirm-btns .ant-btn-primary {
  display: block;
}
.drygry-modal .mobanrenyuan {
  width: 100%;
  height: 100px;
  background: #F8FAFB;
  border-radius: 4px;
  padding: 14px;
}
.drygry-modal .mobanrenyuan p {
  font-size: 12px;
  color: #444444;
  line-height: 25px;
}
.drygry-modal .mobanrenyuan .box {
  margin-top: 10px;
}
.drygry-modal .mobanrenyuan .drygmb {
  width: 100px;
  height: 34px;
  border: 1px solid #356CEA;
  border-radius: 4px;
  line-height: 32px;
  text-align: center;
  font-size: 12px;
  color: #356CEA;
  font-weight: 600;
  margin-right: 10px;
}
.drygry-modal .mobanrenyuan .scwj {
  width: 80px;
  height: 34px;
  border: 1px solid #356CEA;
  border-radius: 4px;
}
.drygry-modal .mobanrenyuan .scwj span {
  font-weight: 600;
}
@media screen and (max-width: 1700px) {
  .ghjg {
    background-color: #fff;
    position: static;
    padding: 0 20px 20px 0;
    margin-top: -54px;
  }
}
