.task-manage {
  height: 100%;
}
.task-manage .btns-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.task-manage .btns-box button {
  width: 100px;
  height: 34px;
}
.task-manage .cc-box {
  width: 100%;
  overflow: scroll;
  height: calc(100vh - 200px);
}
.task-manage .c-box {
  display: flex;
  flex-wrap: wrap;
  width: 1720px;
}
.task-manage .card-box {
  width: 560px;
  height: 420px;
  background: #FFFFFF;
  border: 1px solid #ECEEF7;
  border-radius: 6px;
  padding: 0 10px;
  margin: 0 20px 20px 0;
}
.task-manage .card-box:nth-child(3n) {
  margin-right: 0;
}
.task-manage .card-box .ac {
  display: flex;
  align-items: center;
}
.task-manage .card-box .tt {
  height: 50px;
  align-items: center;
  justify-content: space-between;
}
.task-manage .card-box .tt .time {
  color: #444444;
  font-weight: 500;
}
.task-manage .card-box .tt .tr {
  font-size: 12px;
  align-items: center;
}
.task-manage .card-box .tt .tr img {
  margin-right: 6px;
}
.task-manage .card-box .tt .tr .a {
  color: #356CEA;
  cursor: pointer;
}
.task-manage .card-box .tt .tr .b {
  color: #666666;
  padding: 0 13px;
  border-left: 1px solid #ECEEF7;
  border-right: 1px solid #ECEEF7;
  margin: 0 13px;
  cursor: pointer;
}
.task-manage .card-box .tt .tr .c {
  width: 40px;
  height: 18px;
  padding: 0;
  box-shadow: none;
  border: transparent;
  background-color: transparent;
}
.task-manage .card-box .tt .tr .c span {
  color: #666666;
}
.task-manage .card-box .mm {
  width: 540px;
  height: 300px;
  background-image: linear-gradient(180deg, #F5F7FE 0%, rgba(245, 247, 254, 0) 100%);
  border-radius: 6px 6px 0 0px;
  padding: 20px;
}
.task-manage .card-box .mm .item {
  justify-content: space-between;
}
.task-manage .card-box .mm .item .i {
  width: 50%;
}
.task-manage .card-box .mm .item .i p {
  font-size: 12px;
  line-height: 17px;
}
.task-manage .card-box .mm .item .i .label {
  color: #BEBFC0;
  margin-bottom: 10px;
}
.task-manage .card-box .mm .item .i .value {
  color: #444444;
  font-weight: 500;
  height: 37px;
}
.task-manage .card-box .bb {
  display: flex;
  height: 59px;
  margin-top: 9.5px;
  position: relative;
}
.task-manage .card-box .bb.disabled .b {
  color: #D9D9D9;
  cursor: default;
}
.task-manage .card-box .bb::before {
  content: '';
  width: 560px;
  height: 1px;
  background-color: #ECEEF7;
  position: absolute;
  left: -10px;
  top: 0;
}
.task-manage .card-box .bb .b {
  font-size: 14px;
  color: #356CEA;
  cursor: pointer;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
}
.task-manage .card-box .bb .b img {
  margin-right: 10px;
}
.task-manage .card-box .bb .b:last-child {
  position: relative;
}
.task-manage .card-box .bb .b:last-child:before {
  content: '';
  width: 1px;
  height: 20px;
  background-color: #ECEEF7;
  position: absolute;
  left: 0;
  right: 0;
}
