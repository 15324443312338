.drawer-box {
  height: 100%;
}
.drawer-box .ant-drawer-content {
  height: 100%;
}
.drawer-box .ant-drawer-header {
  position: fixed;
  top: 0;
  width: 600px;
  background: #fff;
  z-index: 99;
}
.drawer-box .detail_box {
  margin-top: 52px;
}
.drawer-box .item-box {
  position: relative;
  display: flex;
  margin-bottom: 14px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.drawer-box .item-box .shopUrlBox {
  width: 120px;
  height: 150px;
  position: relative;
}
.drawer-box .item-box .shopUrlBox .scale {
  display: none;
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 0px;
  left: 0;
  height: 100%;
}
.drawer-box .item-box .shopUrlBox .scale .scale-icon {
  line-height: 150px;
  margin-left: 40px;
}
.drawer-box .item-box .shopUrlBox:hover {
  background: #333;
  opacity: 0.6;
}
.drawer-box .item-box .shopUrlBox:hover .scale {
  display: block;
}
.drawer-box .item-box .file-name {
  font-size: 14px;
}
.drawer-box .item-box .file-name img {
  width: 14px;
  height: 14px;
}
.drawer-box .item-box .file-name:hover {
  background: #ebf5fe;
}
.drawer-box .item-box .shopUrlBox2 {
  width: 120px;
  background: #333;
  opacity: 0.6;
  height: 150px;
  position: relative;
}
.drawer-box .item-box .item-title {
  width: 90px;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
  margin-right: 20px;
  font-weight: bold;
}
.drawer-box .item-box .item-title-2 {
  width: 100px;
  text-align: left;
  font-size: 12px;
  margin-right: 10px;
}
.drawer-box .item-box .item-title-2 .blue {
  color: #4090e2;
}
.drawer-box .item-box .item-content-2 {
  width: 400px;
}
.drawer-box .item-box .item-content-2 .blue {
  color: #4090e2;
}
.drawer-box .item-box .item-img {
  width: 100px;
  height: 100px;
}
.drawer-box .item-box .look_bigimg {
  position: absolute;
  top: 0;
  left: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.4);
}
.drawer-box .item-box .look_bigimg .change_big {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.drawer-box .item-box .plan {
  margin-top: 4px;
  padding: 10px;
  border-radius: 3px;
  background: rgba(78, 78, 78, 0.03);
}
.drawer-box .item-box .margin {
  margin-top: 10px;
}
.drawer-box .divider {
  margin: 0 0 12px;
}
.drawer-box .smallimage {
  height: 150px;
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
}
