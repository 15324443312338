.down-item-box .item {
  display: flex;
  align-items: center;
  width: 460px;
  height: 80px;
  background: #F8FAFB;
  border-radius: 4px;
  padding: 0 10px 0 20px;
  margin-bottom: 10px;
}
.down-item-box .item .txt {
  width: 235px;
  height: 40px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #444444;
  line-height: 20px;
  font-weight: 400;
  margin: 0 25px 0 20px;
  flex: 1;
}
.down-item-box .item button {
  width: 50px;
  height: 22px;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 0;
  line-height: 20px;
}
.down-item-box .item button span {
  font-size: 12px;
  font-weight: 400;
}
.down-item-box .item .cancel-btn {
  border: 1px solid #EEEEEE;
  margin-right: 10px;
}
.down-item-box .item .cancel-btn span {
  color: #444444;
}
.down-item-box .item .download {
  border: 1px solid #356CEA;
}
.down-item-box .item .download span {
  color: #356CEA;
}
.down-item-box .item .download[disabled] {
  border-color: #EEEEEE;
  background-color: #fff;
}
.down-item-box .item .download[disabled] span {
  color: #EEEEEE;
}
