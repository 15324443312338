.contract_record {
  min-height: 700px;
  background: #fff;
  padding: 35px;
  margin-top: 10px;
}
.contract_record .ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
.contract_record .multiple_send_box {
  margin: 20px 0;
}
.contract_record .ant-form-inline .ant-form-item > .ant-form-item-control-wrapper .tip,
.contract_record .multiple_send_box .tip {
  color: red;
  margin-left: 20px;
  font-size: 12px;
}
