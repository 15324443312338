.ct::before {
  content: "";
  font-size: 24px;
}
.flsftd {
  width: 403px;
  padding: 12px;
  line-height: 25px;
  font-size: 12px;
  color: #444444;
}
.flsftd .a {
  font-weight: bold;
}
.dzhd {
  max-width: 305px !important;
}
.dzhd .ant-tooltip-inner {
  width: 296px;
  height: 38px;
  background: #356CEA;
  text-align: center;
  font-size: 12px;
  color: #FFFFFF;
  border-radius: 3px;
  padding: 10px 0;
}
.dzhd .ant-tooltip-arrow {
  border-left-color: #356CEA;
}
.xzdzhd-btn[disabled],
.xzdzhd-btn[disabled]:hover {
  color: #D9D9D9;
  background-color: #fff;
  border: 1px solid #EEEEEE;
}
.xzdzhd-btn img {
  opacity: 0.5;
}
.trade-detail {
  height: 100%;
}
.trade-detail .title {
  font-size: 20px;
  color: #444444;
  font-weight: 600;
}
.trade-detail .flex-x {
  flex-wrap: wrap;
}
.trade-detail .brief-info {
  color: #404d5d;
  font-size: 14px;
  border: 1px solid rgba(31, 56, 88, 0.15);
  padding: 10px;
}
.trade-detail .brief-info > div {
  border-right: 1px solid rgba(31, 56, 88, 0.15);
}
.trade-detail .brief-info > div:last-child {
  border-right: 0px;
}
.trade-detail .brief-info .text-center {
  text-align: center;
}
.trade-detail .brief-info .order-num {
  color: #403737;
  font-size: 24px;
}
.trade-detail .brief-info .blue {
  font-size: 24px;
}
.trade-detail .brief-info .c {
  color: #333;
}
.img-pos-2 {
  position: absolute;
  right: 2px;
  width: 67px;
  height: 67px;
}
.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}
@media screen and (min-width: 1441px) and (max-width: 1576px) {
  .trade-detail .search-box {
    padding-bottom: 54px;
  }
}
