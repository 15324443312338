.fundTableYu {
  display: flex;
  margin-bottom: 20px;
  height: 232px;
  min-width: 950px;
  justify-content: space-between;
  border: 1px solid #DADADA;
  font-size: 12px;
  color: rgba(16, 32, 53, 0.8);
}
.fundTableYu > div {
  flex: 1 1;
}
.fundTableYu > div .header {
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: rgba(31, 56, 88, 0.06);
}
.fundTableYu > div .tableText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  word-break: break-all;
  box-sizing: border-box;
  height: 190px;
  border-right: 1px solid #DADADA;
}
.fundTableYu > div .tableText .total-balance {
  font-size: 16px;
}
.fundTableYu > div .tableText .costDetail {
  padding: 10px;
  height: 105px;
  background: rgba(31, 56, 88, 0.06);
  border-radius: 2px;
}
.fundTableYu > div .tableText .item {
  margin-bottom: 7px;
}
.fundTableYu > div .tableText .item .subject {
  display: inline-block;
  width: 80px;
  text-align: left;
}
.fundTableYu > div .tableText .item .promitIng {
  margin-right: 8px;
  height: 16px;
  width: 16px;
}
.fundTableYu > div .tableText .item-img {
  display: flex;
}
.fundTableYu > div .tableText .btn {
  margin: 0 auto 10px;
  padding: 0;
  width: 72px;
  text-align: center;
}
.fundTableYu > div:last-child .tableText {
  border-right: none;
}
.empty_box {
  display: flex;
  margin: 60px 0;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.45);
  align-items: center;
}
.empty_box img {
  margin-bottom: 10px;
  width: 62px;
  height: 40px;
}
