.find-password {
  padding: 30px;
  position: relative;
}
.find-password .title {
  font-size: 22px;
  color: #444444;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 30px;
}
.find-password .back-btn {
  width: 100px;
  height: 30px;
  background: #f8fafb;
  border-radius: 100px 0 0 100px;
  position: absolute;
  right: 0;
  top: 30px;
  cursor: pointer;
  font-size: 12px;
  color: #444444;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.find-password .back-btn img {
  margin-right: 9px;
}
.find-password .login-form-box .t-label {
  font-size: 12px;
  color: #aaaaaa;
  line-height: 17px;
  margin-bottom: 10px;
}
.find-password .login-form-box .ant-input-affix-wrapper .ant-input-suffix {
  cursor: pointer;
}
.find-password .login-form-box .ant-form-item {
  margin: 0 0 14px 0;
  border-radius: 4px;
  width: 340px;
}
.find-password .login-form-box .ant-form-item .ant-form-item-control {
  line-height: 36px;
}
.find-password .login-form-box .ant-form-item .ant-form-item-control input {
  border-color: #f8fafb;
  height: 36px;
  line-height: 34px;
  background: #f8fafb;
  box-shadow: none;
}
.find-password .login-form-box .ant-form-item .ant-form-item-control input:focus,
.find-password .login-form-box .ant-form-item .ant-form-item-control input:active {
  border-color: #356cea;
}
.find-password .login-form-box .smsCode-box .ant-form-item-children {
  display: flex;
  height: 36px;
  align-items: center;
  justify-content: space-between;
}
.find-password .login-form-box .smsCode-box .ant-form-item-children .code {
  width: 100px;
  height: 36px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}
.find-password .login-form-box .ant-form-explain {
  font-size: 10px;
  margin-top: 2px;
}
.find-password .login-form-box .login-btn {
  width: 340px;
  height: 42px;
  background: #356cea;
  border-radius: 6px;
  font-size: 16px;
  color: #ffffff;
  margin-top: 40px;
}
.find-password .login-footer {
  padding-top: 16px;
  font-size: 12px;
  text-align: center;
}
.find-password .login-footer .copyright {
  padding-right: 12px;
}
.find-password .login-footer .copyright-link {
  color: rgba(0, 0, 0, 0.65);
}
.find-password .login-footer .copyright-link:hover {
  color: #356cea;
}
