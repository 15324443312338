.start_sign {
  position: relative;
  height: 100%;
  background: #ffffff;
  border-radius: 6px;
  padding: 10px;
  min-width: 1240px;
}
.start_sign .container {
  background-image: linear-gradient(180deg, #f2f4f8 0%, rgba(242, 244, 248, 0) 98%);
  background-size: 100% 300px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}
.start_sign .container .c-boxs {
  width: 1200px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  position: relative;
}
.start_sign .container .c-boxs .route_to_hua {
  font-size: 12px;
  color: #aaaaaa;
  line-height: 17px;
  position: absolute;
  right: 20px;
  top: 47px;
}
.start_sign .container .c-boxs .route_to_hua a {
  color: #356cea;
  text-decoration: underline;
}
.start_sign .container .fqqs {
  min-width: 100px;
  height: 40px;
  background: #356cea;
  border-radius: 4px;
}
.start_sign .container .fqqs span {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
}
.start_sign .container .select-table-box .ant-table-tbody > tr > td {
  padding: 10px 20px;
}
.start_sign .container .ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #fff;
  border-bottom: 1px solid #eeeeee;
}
.start_sign .container .steptwo-search-box {
  height: 54px;
  padding-top: 19.5px;
  border-top: 1px solid #e9ecf0;
  margin: 19.5px 0 14px 0;
}
.start_sign .container .steptwo-search-box .ant-form-item {
  height: 34px;
}
.start_sign .container .steptwo-search-box .ant-form-item .ant-form-item-control {
  line-height: 32px;
}
.start_sign .container .stepone-search-box {
  position: relative;
  height: 54px;
  padding-top: 19.5px;
  border-top: 1px solid #e9ecf0;
  margin: 19.5px 0 14px 0;
}
.start_sign .container .stepone-search-box .ant-input {
  margin: 0 14px;
}
.start_sign .container .stepone-search-box .clear-all-btn {
  width: 70px;
  height: 24px;
  border: 1px solid #356cea;
  border-radius: 17px;
  font-size: 12px;
  color: #356cea;
  line-height: 22px;
  text-align: center;
  margin-left: 20px;
  cursor: pointer;
}
.start_sign .container .stepone-search-box .icon-search {
  position: absolute;
  right: 90px;
  top: 30px;
  width: 12px;
  height: 12px;
}
.start_sign .container .stepone-search-box .select-number {
  color: #aaaaaa;
  font-size: 12px;
  line-height: 26px;
}
.start_sign .container .stepone-search-box .select-number span {
  color: #666666;
}
.start_sign .container .step-two-box .ant-table {
  margin-top: 20px;
}
.start_sign .container .step-two-box .ant-table input {
  height: 30px;
  padding: 0;
  text-align: center;
}
.start_sign .container .step-two-box .footer_count {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: #bebfc0;
  position: absolute;
  left: 0;
  bottom: 16px;
}
.start_sign .container .step-one-content {
  display: flex;
}
.start_sign .container .step-one-box .ant-table,
.start_sign .container .step-two-box .ant-table {
  border: 1px solid #eeeeee;
  border-radius: 6px;
}
.start_sign .container .step-one-box .ant-table-wrapper .ant-table-tbody > tr:last-child > td,
.start_sign .container .step-two-box .ant-table-wrapper .ant-table-tbody > tr:last-child > td {
  border: none;
}
.start_sign .container .step-one-box td,
.start_sign .container .step-two-box td {
  color: #666666;
}
.start_sign .container .step-one-box {
  flex: 1;
  margin-right: 20px;
}
.start_sign .container .step-one-box:last-child {
  margin-right: 0;
}
.start_sign .container .table-box {
  position: relative;
}
.start_sign .container .table-box .ant-table-wrapper {
  min-height: 100px;
}
.start_sign .container .table-box .ant-table-wrapper .ant-spin-nested-loading {
  min-height: 100px;
}
.start_sign .container .select-box {
  height: 32px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 16px;
}
.start_sign .container .select-box .ant-checkbox + span {
  padding-right: 0;
}
.start_sign .container .select-box span {
  font-size: 12px;
  color: #bebfc0;
}
.start_sign .container .select-box .clear-all-btn {
  width: 70px;
  height: 24px;
  border: 1px solid #356cea;
  border-radius: 17px;
  font-size: 12px;
  color: #356cea;
  line-height: 22px;
  text-align: center;
  margin-left: 20px;
  cursor: pointer;
}
.start_sign .container .step-img-box {
  width: 1200px;
  height: 90px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.start_sign .container .step-img-box .txt-box {
  display: flex;
  justify-content: space-between;
  width: 585px;
  height: 17px;
  margin-top: 10px;
  position: relative;
}
.start_sign .container .step-img-box .txt-box .item1 {
  font-size: 12px;
  text-align: center;
  min-width: 62px;
  position: absolute;
  left: -20px;
  top: 0;
}
.start_sign .container .step-img-box .txt-box .item2 {
  font-size: 12px;
  text-align: center;
  width: 90px;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -21px;
}
.start_sign .container .step-img-box .txt-box .item3 {
  font-size: 12px;
  text-align: center;
  width: 25px;
  position: absolute;
  right: 0%;
  top: 0;
  margin-right: -12px;
}
.start_sign .container .back {
  margin-bottom: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
}
.start_sign .container .back a {
  color: rgba(0, 0, 0, 0.8);
}
.start_sign .container .base_info {
  font-size: 12px;
  color: #aaaaaa;
}
.start_sign .container .base_info:first-child {
  margin-bottom: 10px;
}
.start_sign .container .base_info .name {
  color: #444444;
  font-weight: 500;
  margin-left: 14px;
}
.start_sign .container .step_style {
  width: 45%;
  margin: 0 auto 35px;
}
.start_sign .container .transfer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 100%;
  justify-content: center;
}
.start_sign .container .transfer .transfer_table {
  width: 427px;
  height: 376px;
  overflow: auto;
  padding: 16px;
  border-radius: 3px;
  border: 1px solid rgba(31, 56, 88, 0.2);
}
.start_sign .container .transfer .transfer_table .ant-checkbox-wrapper {
  margin-left: 0;
  font-size: 12px;
  color: rgba(31, 56, 88, 0.6);
}
.start_sign .container .transfer .transfer_table .collect {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: rgba(31, 56, 88, 0.6);
}
.start_sign .container .transfer .transfer_table .check_child {
  display: flex;
  align-items: flex-start;
  height: 46px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
.start_sign .container .transfer .transfer_table .check_child > span:last-child {
  display: flex;
  align-items: flex-start;
}
.start_sign .container .transfer .transfer_table .check_info {
  word-break: break-all;
  display: inline-block;
}
.start_sign .container .transfer .transfer_table .check_info_name {
  width: 72px;
}
.start_sign .container .transfer .transfer_table .check_info_status {
  width: 36px;
}
.start_sign .container .transfer .transfer_table .check_info_idcard {
  width: 152px;
}
.start_sign .container .transfer .transfer_table .check_info_mobile {
  width: 97px;
}
.start_sign .container .transfer .transfer_table .check_info_delete {
  margin-left: 12px;
  width: 24px;
}
.start_sign .container .transfer .transfer_table .cursor {
  cursor: pointer;
}
.start_sign .container .transfer .transfer_table:first-child {
  margin-right: 17px;
}
.start_sign .container .success_box {
  padding-top: 74px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.start_sign .container .success_box img {
  height: 107px;
  width: 98px;
}
.start_sign .container .success_box .big-txt {
  font-size: 20px;
  color: #444444;
  text-align: center;
  font-weight: 500;
  margin: 40px 0 20px 0;
  line-height: 28px;
}
.start_sign .container .success_box .txt1 {
  font-size: 14px;
  color: #666666;
  margin-bottom: 20px;
}
.start_sign .container .success_box .txt2 {
  font-size: 14px;
  color: #666666;
  text-align: center;
  line-height: 20px;
  margin-bottom: 31px;
}
.start_sign .container .success_box .txt2 span {
  color: #356cea;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.start_sign .container .success_box .txt-box {
  margin: 0 auto 20px auto;
  width: 600px;
  height: 40px;
  background-image: linear-gradient(269deg, rgba(242, 244, 248, 0) 0%, #f2f4f8 51%, rgba(242, 244, 248, 0) 100%);
  display: flex;
  justify-content: center;
}
.start_sign .container .success_box .txt-box .item {
  font-size: 12px;
  color: #666666;
  margin: 0 20px;
  line-height: 40px;
}
.start_sign .container .success_box .b-box {
  display: flex;
  justify-content: center;
  width: 100%;
}
.start_sign .container .success_box .b-box .dzqygl {
  width: 120px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.start_sign .container .success_box .b-box .dzqygl span {
  font-size: 16px;
  color: #666666;
  font-weight: 500;
}
.start_sign .container .success_box .success {
  margin: 12px auto 4px;
  font-size: 26px;
  color: rgba(31, 56, 88, 0.8);
}
.start_sign .container .success_box .success_count {
  margin-bottom: 20px;
  font-size: 12px;
  color: rgba(31, 56, 88, 0.4);
}
.start_sign .container .footer {
  height: 60px;
  line-height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
}
