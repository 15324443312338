.new-page-info-detail .news-detail-content {
  width: 920px;
  background: #F8FAFB;
  border-radius: 4px;
  padding: 20px;
}
.new-page-info-detail .news-detail-content img {
  max-width: 100%;
}
.new-page-info-detail .download-btn {
  width: 60px;
  height: 24px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 20px;
  text-align: center;
  display: inline-block;
  line-height: 22px;
  font-size: 12px;
  color: #666666;
}
.new-page-info-detail .download-btn img {
  margin-right: 6px;
}
.new-page-info-detail .file-box {
  width: 920px;
  background: #F8FAFB;
  border-radius: 4px;
  padding: 20px;
  margin-top: 10px;
}
.new-page-info-detail .file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
