.page-news {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 10px;
  position: relative;
}
.page-news .c-box {
  height: 916px;
  background-image: linear-gradient(180deg, #F2F4F8 0%, rgba(242, 244, 248, 0) 98%);
  border-radius: 6px 6px 0 0px;
  background-size: 100% 300px;
  background-repeat: no-repeat;
  padding-top: 20px;
}
.page-news .list-box {
  height: calc(100vh - 240px);
}
.page-news .news-breadcrumb {
  width: 1200px;
  height: 34px;
  margin: 0 auto 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-news .news-breadcrumb .a {
  font-size: 20px;
  color: #444444;
  font-weight: 600;
}
.page-news .news-breadcrumb .yjyd {
  width: 90px;
  height: 34px;
}
.page-news .empty {
  margin-top: 50px;
  color: rgba(0, 0, 0, 0.25);
}
.page-news .loading {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 222;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-news .ant-pagination {
  padding: 20px 0;
  text-align: center;
}
.page-news .ant-tabs-bar {
  margin: 0;
}
.info-item {
  position: relative;
  cursor: pointer;
  padding: 18px 20px;
  font-size: 12px;
  width: 1200px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
  margin: 0 auto 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-item .title {
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #444;
  padding-left: 10px;
}
.info-item .time {
  color: #444444;
  font-weight: 500;
}
.info-item:hover {
  background-color: #F9F9FA;
}
.info-item.unred::after {
  position: absolute;
  content: "●";
  left: 10px;
  top: 18px;
  color: #F63B3F;
  transform: scale(0.5);
}
