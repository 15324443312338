.choose-company-modal .ant-modal-content,
.choose-company-modal .ant-modal-body {
  padding: 0;
  width: 400px;
  min-height: 330px;
}
.choose-company-modal .ant-modal-close {
  display: none;
}
.choose-company-modal .choose-company {
  background-image: linear-gradient(0deg, #FFFFFF 0%, #F2F6FD 99%);
  border-radius: 10px;
  width: 400px;
  position: relative;
  padding: 40px 40px 0 40px;
}
.choose-company-modal .choose-company .bg {
  position: absolute;
  top: -35px;
  right: 40px;
}
.choose-company-modal .choose-company .tt {
  font-size: 20px;
  color: #333333;
  line-height: 28px;
  margin-bottom: 40px;
  font-weight: 600;
}
.choose-company-modal .choose-company .item-box {
  max-height: 205px;
}
.choose-company-modal .choose-company .item {
  background: #FFFFFF;
  border: 1px solid #ECEEF7;
  border-radius: 6px;
  width: 320px;
  height: 40px;
  padding: 0 10px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}
.choose-company-modal .choose-company .item:last-child {
  margin-bottom: 0;
}
.choose-company-modal .choose-company .item span {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
}
.choose-company-modal .choose-company .btn-box {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.choose-company-modal .choose-company .btn-box .comfirm-btn {
  width: 320px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background: #356CEA;
  border-radius: 6px;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #FFFFFF;
  cursor: pointer;
}
.choose-company-modal .choose-sys {
  width: 400px;
  position: relative;
  padding: 42px 40px 28px 40px;
  background-image: linear-gradient(0deg, #FFFFFF 0%, #F2F6FD 99%);
  border-radius: 10px;
}
.choose-company-modal .choose-sys .bg {
  position: absolute;
  top: -35px;
  right: 40px;
}
.choose-company-modal .choose-sys .tt {
  font-size: 20px;
  color: #333333;
  line-height: 28px;
  margin-bottom: 40px;
  font-weight: 600;
}
.choose-company-modal .choose-sys .bb {
  display: flex;
  justify-content: space-between;
}
.choose-company-modal .choose-sys .bb .gth,
.choose-company-modal .choose-sys .bb .wtdz {
  width: 150px;
  height: 140px;
  background: #356CEA;
  border-radius: 10px;
  background-size: 150px 140px;
  cursor: pointer;
}
.choose-company-modal .choose-sys .bb .gth {
  background-image: url('https://imagesize.hrwork.com/sp/files/e020caff-e688-4a5e-bafe-29450cae9fe8.png');
}
.choose-company-modal .choose-sys .bb .gth:hover {
  background-image: url('https://imagesize.hrwork.com/sp/files/ff739d15-3a40-4011-a20c-7394701d0383.png');
}
.choose-company-modal .choose-sys .bb .wtdz {
  background-image: url('https://imagesize.hrwork.com/sp/files/900e554e-492e-40f0-99ba-0824df14bd36.png');
}
.choose-company-modal .choose-sys .bb .wtdz:hover {
  background-image: url('https://imagesize.hrwork.com/sp/files/e5043459-83b4-45da-b108-459262e99a9b.png');
}
.choose-company-modal .choose-sys .back {
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #356CEA;
  font-weight: 600;
  width: 320px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #356CEA;
  border-radius: 6px;
  margin-top: 30px;
  cursor: pointer;
}
