.zyt-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 0 0;
  height: 60px;
  background: rgba(31, 56, 88, 0.06);
}
.zyt-header .right-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding-right: 20px;
  height: 50px;
  flex: 1;
  background-color: #fff;
}
.zyt-header .right-menu .right-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.zyt-header .right-menu .line-box {
  width: 1px;
  height: 20px;
  background-color: #ECEEF7;
  margin: 0 19.5px;
}
.zyt-header .right-menu .newline-box {
  margin-left: 0;
}
.zyt-header .right-menu .ling-tip-box {
  display: flex;
  align-items: center;
}
.zyt-header .right-menu .ling-tip-box .ling-tip {
  color: #FF742A;
  font-size: 14px;
  background-color: rgba(249, 190, 2, 0.2);
  padding: 0 3px;
  margin-left: 10px;
}
.zyt-header .logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 60px;
  background-color: #363636;
}
.zyt-header .logo-box .logo-img {
  margin-right: 8px;
  width: 24px;
}
.zyt-header .logo-box span {
  font-size: 16px;
  color: #424242;
  display: inline-block;
  width: 75px;
  color: #fff;
}
.zyt-header .big-Menu {
  margin-left: 6px;
  flex: 1;
}
.zyt-header .big-Menu .btn {
  padding: 0;
  margin-left: 40px;
  border: none;
  box-shadow: none;
  font-size: 14px;
}
.zyt-header .big-Menu .btn.active {
  color: #40a9ff;
}
.zyt-header .user-info .user-avater {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.zyt-header .menu-box {
  margin-right: 10px;
}
.zyt-header .menu-box .menu-list {
  color: rgba(0, 0, 0, 0.65);
}
.zyt-header .h-company {
  font-size: 12px;
  color: #444444;
  display: flex;
  align-items: center;
}
.zyt-header .h-company .company-tip {
  width: 6px;
  height: 8px;
  border: 4px solid #fff;
  border-top: 4px solid #444444;
  margin-left: 10px;
  margin-top: 5px;
}
.ant-dropdown-menu {
  max-height: 400px;
  overflow: auto;
}
.lgb-tip-box {
  width: 260px;
  background: #FFFFFF;
  padding-top: 10px;
}
.lgb-tip-box .a {
  width: 240px;
  height: 34px;
  font-size: 12px;
  color: #444444;
  margin: 0 10px 10px 10px;
}
.lgb-tip-box .c {
  font-size: 12px;
  color: #FF5C5C;
  line-height: 17px;
  margin: 0 10px 9.5px 10px;
}
.lgb-tip-box .d {
  padding-top: 5.5px;
  padding-bottom: 7px;
  border-top: 1px solid #ECEEF7;
  text-align: center;
}
.lgb-tip-box .d a {
  font-size: 12px;
  color: #356CEA;
}
.message-tip .ant-tooltip-inner {
  width: 70px;
  text-align: center;
  padding: 7px 10px;
  background: #356CEA;
  font-size: 12px;
  color: #FFFFFF;
  border-radius: 3px;
}
.message-tip .ant-tooltip-arrow {
  border-bottom-color: #356CEA;
}
.accountTypeBtn-header {
  display: flex;
  font-weight: bolder;
  justify-content: space-between;
  padding-left: 10px;
}
.accountTypeBtn-header .item {
  cursor: pointer;
  width: 160px;
  height: 30px;
  background: #FBFCFD;
  border: 1px solid #ECEEF7;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  color: #444444;
  font-weight: 500;
  padding-left: 5px;
  padding-left: 12px;
}
.accountTypeBtn-header .item span {
  width: 100px;
  display: inline-block;
  margin-left: 6px;
}
.choose-company-modal.choose-spe .ant-modal-content,
.choose-company-modal.choose-spe .ant-modal-body {
  min-height: 290px;
}
.ty-company-box {
  position: relative;
}
