.sub-title {
  font-weight: bold;
  font-size: 16px;
  padding-top: 10px;
}
.fund-account .data-info .amount-info {
  width: 350px;
  padding: 20px;
  margin-top: 10px;
  border: 1px solid #dadada;
}
.fund-account .data-info .amount-info p {
  font-size: 14px;
}
.fund-account .data-info .amount-info .amount {
  margin: 1px 0;
  font-size: 28px;
  color: #000;
}
.fund-account .data-info .amount-info .freeze {
  margin-bottom: 5px;
  padding: 0 20px;
  background: #e6f2fe;
}
.pay-detail .margin_b {
  margin-bottom: 12px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
.pay-detail .top_reminder {
  padding-left: 7px;
  width: 434px;
  height: 38px;
  line-height: 38px;
  border: 1px solid #A1D0EF;
  background: #DEEFFA;
}
.pay-detail .account_title {
  margin: 18px 0 10px;
  color: rgba(0, 0, 0, 0.8);
}
.pay-detail .key {
  width: 72px;
}
