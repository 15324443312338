.detail-header {
  width: 100%;
  overflow: scroll;
  margin-bottom: 20px;
}
.detail-header .c-box {
  width: 1720px;
  display: flex;
  height: 160px;
  justify-content: space-between;
}
.detail-header .c-box .item {
  width: 850px;
  height: 160px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.detail-header .c-box .item .big-img {
  margin-right: 40px;
}
.detail-header .c-box .item .a {
  width: 105px;
  margin-right: 33px;
}
.detail-header .c-box .item .a:last-child {
  margin-right: 0;
}
.detail-header .c-box .item .a .t {
  font-size: 12px;
  color: #666666;
  line-height: 17px;
  margin-bottom: 10px;
}
.detail-header .c-box .item .a .b {
  font-size: 20px;
  color: #444444;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 28px;
}
.detail-header .c-box .item .a .b img {
  margin-right: 10px;
}
.detail-header .c-box .item .c {
  width: 220px;
  display: flex;
}
.detail-header .c-box .item .c img {
  margin-right: 20px;
}
.detail-header .c-box .item .c .r {
  flex: 1;
}
.detail-header .c-box .item .c .r .t {
  font-size: 12px;
  color: #666666;
  margin-bottom: 10px;
}
.detail-header .c-box .item .c .r .b {
  font-size: 20px;
  font-weight: 500;
  color: #444444;
  line-height: 28px;
}
.detail-header .c-box .item .c .r .b.bb {
  color: #356CEA;
}
.detail-header .c-box .item .m {
  margin: 0 20px;
}
.detail-header .c-box .item2 {
  justify-content: center;
}
