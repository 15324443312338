.register_new {
  padding-bottom: 59px;
}
.register_new .ant-form label {
  display: inline-block;
  width: 90px;
}
.register_new .ant-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}
.register_new .ant-row .ant-form-item-label {
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  height: 17px;
  line-height: 17px;
}
.register_new .ant-row .ant-form-item-label label {
  font-size: 12px;
  color: #666666;
}
.register_new .ant-row .ant-form-item-required::before {
  display: none;
}
.register_new .ant-row .ant-form-item-control {
  line-height: 34px;
}
.register_new .sendcode .ant-form-item-children {
  width: 460px;
  display: flex;
}
.register_new .funcStyle {
  width: 460px;
  background: #F8FAFB;
  border: 1px solid #E9ECF0;
  padding: 5px 13px;
  line-height: 24px;
  border-radius: 4px;
  font-size: 12px;
}
.register_new .funcStyle .func-name {
  margin: 0 10px 10px 0 ;
  font-size: 12px;
  color: #8C8D8D;
}
.register_new .remind-box {
  width: 500px;
  height: 127px;
  background: #F8FAFB;
  border-radius: 0 0 4px 4px;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 20px;
}
.register_new .remind-box .remind {
  font-size: 12px;
  color: #444444;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 17px;
}
.register_new .remind-box p {
  font-size: 12px;
  color: #444444;
  line-height: 30px;
}
