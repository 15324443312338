.detail-header-box {
  display: flex;
  height: 160px;
  width: 100%;
  margin-bottom: 20px;
}
.detail-header-box .box {
  width: 850px;
  height: 160px;
  background: #FFFFFF;
  border-radius: 4px;
}
.detail-header-box .box-left {
  display: flex;
  align-items: center;
  padding: 10px;
}
.detail-header-box .box-left p {
  font-size: 12px;
  color: #666666;
  font-weight: 400;
}
.detail-header-box .box-left .value {
  margin-top: 10px;
  font-size: 20px;
  color: #356CEA;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.detail-header-box .box-left .value img {
  margin-right: 10px;
}
.detail-header-box .box-left .box-l {
  width: 170px;
  margin-left: 40px;
}
.detail-header-box .box-left .box-ll {
  width: 170px;
  margin: 0 20px;
}
.detail-header-box .box-left .box-ll .value {
  color: #444444;
}
.detail-header-box .box-left .box-lll {
  width: 170px;
}
.detail-header-box .box-left .box-lll .value {
  color: #444444;
}
.detail-header-box .box-r {
  margin-left: 20px;
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-header-box .box-r .item2 {
  margin: 0 56px;
}
.detail-header-box .box-r .item {
  width: 180px;
  display: flex;
  align-items: center;
}
.detail-header-box .box-r .item img {
  margin-right: 20px;
}
.detail-header-box .box-r .item p {
  font-size: 12px;
  color: #666666;
  font-weight: 400;
}
.detail-header-box .box-r .item .r {
  font-size: 20px;
  color: #444444;
  font-weight: 500;
  margin-top: 10px;
}
.detail-header-box .box-r .item .rr {
  font-size: 20px;
  color: #356CEA;
  font-weight: 500;
  margin-top: 10px;
}
