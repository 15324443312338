.money-box {
  background: #DEEFFA;
  border: 1px solid #A1D0EF;
  line-height: 36px;
  padding: 0 15px;
  margin-bottom: 10px;
  font-size: 12px;
}
.money-box span {
  margin-right: 10px;
}
.page-statistic {
  height: 100%;
}
.page-statistic .search-box {
  position: relative;
}
.page-statistic .money-info {
  height: 160px;
  width: 100%;
  margin-bottom: 20px;
}
.page-statistic .money-info .money-info-box {
  width: 1720px;
  display: flex;
}
.page-statistic .money-info .item-m {
  margin: 0 20px;
}
.page-statistic .money-info .item {
  width: 560px;
  height: 160px;
  background: #FFFFFF;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.page-statistic .money-info .item img {
  margin-right: 50px;
}
.page-statistic .money-info .item .r-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.page-statistic .money-info .item .r-box .t {
  font-size: 14px;
  color: #444444;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
}
.page-statistic .money-info .item .r-box .b {
  display: flex;
  align-items: center;
}
.page-statistic .money-info .item .r-box .b .b-box:first-child {
  margin-right: 30px;
}
.page-statistic .money-info .item .r-box .b .b-box .p1 {
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 10px;
}
.page-statistic .money-info .item .r-box .b .b-box .p1 img {
  margin-right: 10px;
}
.page-statistic .money-info .item .r-box .b .b-box .p2 {
  font-size: 20px;
  color: #444444;
  font-weight: 500;
  line-height: 28px;
}
@media screen and (max-width: 1440px) {
  .sm-search-box .sb-lbs p {
    height: 0;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1576px) {
  .page-statistic .search-box {
    padding-bottom: 54px;
  }
}
