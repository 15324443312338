.user-center .tt-box {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 20px;
  display: flex;
}
.user-center .tt-box .tab {
  font-size: 14px;
  color: #444444;
  font-weight: 500;
  line-height: 20px;
  margin-right: 40px;
  cursor: pointer;
}
.user-center .tt-box .tab.active {
  color: #356CEA;
  position: relative;
}
.user-center .tt-box .tab.active::before {
  content: '';
  width: 20px;
  height: 3px;
  background: #356CEA;
  position: absolute;
  left: 50%;
  bottom: -20px;
  margin-left: -10px;
}
.user-center .bb-box {
  width: 100%;
  height: calc(100vh - 160px);
  background: #FFFFFF;
  border-radius: 6px;
  display: flex;
  padding: 4px;
  margin-top: 20px;
}
.user-center .bb-box .ll {
  width: 100px;
  background: #F8FAFB;
  border-radius: 6px 0 0 6px;
  padding: 20px;
}
.user-center .bb-box .ll .item {
  font-size: 12px;
  color: #444444;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 20px;
}
.user-center .bb-box .rr {
  margin-left: 14px;
  padding-top: 20px;
}
.user-center .bb-box .rr .item {
  margin-bottom: 20px;
  display: flex;
}
.user-center .bb-box .rr .item .btn {
  width: 80px;
  height: 34px;
  border: 1px solid #356CEA;
  border-radius: 4px;
  line-height: 32px;
  font-size: 12px;
  color: #356CEA;
  font-weight: 500;
  margin-left: 9px;
  text-align: center;
  cursor: pointer;
}
.user-center .bb-box .rr .item .txt {
  width: 300px;
  height: 34px;
  background: #F8FAFB;
  border: 1px solid #E9ECF0;
  border-radius: 4px;
  font-size: 12px;
  color: #AAAAAA;
  font-weight: 400;
  padding: 8px 14px;
}
.update-base {
  background-color: #fff;
  height: 700px;
  padding: 60px 50px;
  margin-top: 10px;
}
.update-base .i-box {
  width: 500px;
  margin: 0 auto;
}
.update-base .i-box .m-box {
  padding: 30px 0;
}
.update-base .icon-box {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 16px;
  line-height: 30px;
  box-sizing: border-box;
  color: #1890ff;
  border: 1px solid #1890ff;
}
.update-base .icon-box.active {
  color: #fff;
  background: #1890ff;
  border: 1px solid #1890ff;
}
