.sign-box .tt {
  width: 100%;
  overflow: scroll;
  display: none;
}
.sign-box .tt .tt-c {
  width: 1720px;
  display: flex;
  justify-content: space-between;
}
.sign-box .tt .tt-c .item {
  width: 850px;
  height: 160px;
  background: #FFFFFF;
  border-radius: 4px;
}
.sign-box .tt .tt-c .item1 .ll .p1 {
  font-size: 12px;
  color: #666666;
}
.sign-box .tt .tt-c .item1 .ll .p2 {
  font-size: 20px;
  color: #444444;
  font-weight: 500;
}
.sign-box .tt .tt-c .item1 .ll .p2 img {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 10px;
}
