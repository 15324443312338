.update-password-box {
  background-image: linear-gradient(180deg, #F8FAFB 0%, #F8FAFB 100%);
  background-size: 100px 100%;
  background-position: left top;
  background-repeat: no-repeat;
  padding-top: 14px;
  width: 100%;
}
.update-password-box .ant-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.update-password-box .ant-form-item .ant-form-item-label {
  width: 100px;
  text-align: left;
  padding-left: 14px;
}
.update-password-box .ant-form-item .ant-form-item-required::before {
  display: none;
}
.update-password-box .ant-form-item .ant-form-item-control-wrapper {
  margin-left: 14px;
  width: 400px;
}
.update-password-box .save-btn {
  width: 80px;
  height: 34px;
  background: #356CEA;
  border-radius: 4px;
  margin-left: 100px;
  margin-top: 20px;
}
.update-password-box .save-btn span {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 500;
  line-height: 32px;
}
