.login-form {
  padding: 30px;
}
.login-form .title {
  font-size: 22px;
  color: #444444;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 30px;
}
.login-form .forget {
  cursor: pointer;
}
.login-form .login-form-box .t-label {
  font-size: 12px;
  color: #AAAAAA;
  line-height: 17px;
  margin-bottom: 10px;
}
.login-form .login-form-box .remember-box .ant-form-item-children {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login-form .login-form-box .remember-box .ant-form-item-children .find-psd {
  font-size: 12px;
  color: #444444;
  margin-left: 6px;
}
.login-form .login-form-box .ant-input-affix-wrapper .ant-input-suffix {
  cursor: pointer;
}
.login-form .login-form-box .ant-form-item {
  margin: 0 0 14px 0;
  border-radius: 4px;
  width: 340px;
}
.login-form .login-form-box .ant-form-item .ant-form-item-control {
  line-height: 36px;
}
.login-form .login-form-box .ant-form-item .ant-form-item-control input {
  border-color: #F8FAFB;
  height: 36px;
  line-height: 36px;
  background: #F8FAFB;
  box-shadow: none;
}
.login-form .login-form-box .ant-form-item .ant-form-item-control input:focus,
.login-form .login-form-box .ant-form-item .ant-form-item-control input:active {
  border-color: #356CEA;
}
.login-form .login-form-box .ant-form-item .ant-form-item-control.has-error .ant-input,
.login-form .login-form-box .ant-form-item .ant-form-item-control.has-error .ant-input:hover {
  border-color: #f5222d;
}
.login-form .login-form-box .smsCode-box .ant-form-item-children {
  display: flex;
  height: 36px;
  align-items: center;
  justify-content: space-between;
}
.login-form .login-form-box .smsCode-box .ant-form-item-children .img-code {
  width: 100px;
  height: 36px;
  border: 1px solid #EFEFEF;
  border-radius: 4px;
  line-height: 36px;
}
.login-form .login-form-box .smsCode-box .ant-form-item-children .img-code img {
  margin-bottom: 30px;
}
.login-form .login-form-box .ant-form-explain {
  font-size: 10px;
  margin-top: 2px;
}
.login-form .login-form-box .login-btn {
  width: 340px;
  height: 42px;
  background: #356CEA;
  border-radius: 6px;
  font-size: 16px;
  color: #FFFFFF;
}
.login-form .login-footer {
  padding-top: 16px;
  font-size: 12px;
  text-align: center;
}
.login-form .login-footer .copyright {
  padding-right: 12px;
}
.login-form .login-footer .copyright-link {
  color: rgba(0, 0, 0, 0.65);
}
.login-form .login-footer .copyright-link:hover {
  color: #356cea;
}
