.update-manager-box {
  width: 100%;
}
.update-manager-box .tt {
  width: 100%;
  height: 40px;
  background: #EAF0FC;
  border-radius: 6px 6px 0px 0px;
  line-height: 40px;
  padding-left: 14px;
  margin-bottom: 4px;
  font-size: 12px;
  color: #444444;
}
.update-manager-box .bb {
  display: flex;
  height: calc(100% - 44px);
}
.update-manager-box .bb .ll {
  width: 100px;
  height: 100%;
  background: #F8FAFB;
  border-radius: 6px;
  padding: 20px 14px;
}
.update-manager-box .bb .ll .item {
  font-size: 12px;
  color: #444444;
  font-weight: 500;
  height: 17px;
  line-height: 17px;
  margin-bottom: 20px;
}
.update-manager-box .bb .rr {
  margin-left: 14px;
}
.update-manager-box .bb .rr .item {
  font-size: 12px;
  color: #666666;
  height: 17px;
  line-height: 17px;
  margin-bottom: 20px;
}
.update-manager-box .bb .rr .update-btn {
  width: 80px;
  height: 34px;
  border: 1px solid #356CEA;
  border-radius: 4px;
}
.update-manager-box .bb .rr .update-btn span {
  font-size: 14px;
  color: #356CEA;
  font-weight: 500;
}
.update-manager-modal .ant-form-item-label {
  height: 17px;
  line-height: 17px;
  margin-bottom: 10px;
}
.update-manager-modal .ant-form-item-label label {
  color: #666666;
}
.update-manager-modal .ant-form-item {
  margin-bottom: 14px;
}
.update-manager-modal .ant-form-item-control-wrapper {
  min-height: 34px;
}
.update-manager-modal .ant-form-item-control-wrapper .ant-form-item-control {
  min-height: 34px;
  line-height: 34px;
}
.update-manager-modal .disabled-input {
  width: 460px;
  height: 34px;
  background: #F8FAFB;
  border: 1px solid #E9ECF0;
  border-radius: 4px;
  padding: 8px 14px 9px 14px;
  font-size: 12px;
  color: #AAAAAA;
  line-height: 17px;
}
.update-manager-modal .blue-action-btn {
  height: 34px;
  width: 80px;
  padding: 0;
}
.update-manager-modal .bb {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0 0;
  margin-top: 20px;
}
.update-manager-modal .confirm-btn {
  width: 90px;
  height: 40px;
  background: #356CEA;
  border-radius: 4px;
  z-index: 10;
}
.update-manager-modal .confirm-btn span {
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 500;
}
.update-manager-modal .tip-box {
  width: 500px;
  height: 80px;
  background: #F8FAFB;
  border-radius: 0 0 4px 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px 0 25px 20px;
  font-size: 12px;
  color: #444444;
  line-height: 30px;
  z-index: 1;
}
