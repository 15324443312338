.sqkp-modal .ant-form-item-label {
  text-align: left;
}
.sqkp-modal .ant-form-item-control-wrapper {
  min-height: 34px !important;
}
.sqkp-modal .ant-form-item-control {
  line-height: 32px;
}
.sqkp-modal .sqkp-form {
  height: 500px;
}
.sqkp-modal .ant-modal-body {
  padding: 20px 20px 0 20px;
}
.sqkp-modal .item-box {
  display: flex;
  justify-content: space-between;
}
.sqkp-modal .item-box .ant-form-item-label {
  width: 220px !important;
}
.sqkp-modal .item-box input {
  width: 220px !important;
  height: 34px;
  border-radius: 4px;
}
.sqkp-modal textarea.ant-input {
  height: auto;
  margin-bottom: 0;
}
.sqkp-modal .ant-form-item {
  display: flex;
  flex-direction: column;
}
.sqkp-modal .ant-form-item-label {
  font-size: 12px;
  color: #666666;
  line-height: 17px;
  margin-bottom: 10px;
}
.sqkp-modal .ant-form-item-required::before {
  display: none;
}
.sqkp-modal .ant-select-selection--single {
  width: 220px;
  height: 34px;
  border-radius: 4px;
}
