.lot-remit-detail {
  height: 100%;
  overflow: auto;
}
.lot-remit-detail .ant-form-item-control {
  line-height: 28px;
}
.lot-remit-detail .ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 10px 20px;
  height: 50px;
}
.lot-remit-detail .title {
  font-size: 20px;
  color: #444444;
  font-weight: 600;
}
.lot-remit-detail .detail-action {
  display: flex;
}
.lot-remit-detail .detail-action .action-btn {
  margin-bottom: 0;
  margin-right: 10px;
}
.lot-remit-detail .save-btn {
  border: 1px solid #356CEA;
  color: #356CEA;
}
.lot-remit-detail .lot-remit-detail-table .ant-form-item,
.lot-remit-detail .lot-remit-detail-table .ant-form-item-control-wrapper,
.lot-remit-detail .lot-remit-detail-table .ant-form-item-control {
  min-height: 30px;
}
.lot-remit-detail .lot-remit-detail-table .ant-form-item input,
.lot-remit-detail .lot-remit-detail-table .ant-form-item-control-wrapper input,
.lot-remit-detail .lot-remit-detail-table .ant-form-item-control input {
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 10px;
  text-align: left;
}
.lot-remit-detail .lot-remit-detail-table .ant-form-item .id_card-input-box,
.lot-remit-detail .lot-remit-detail-table .ant-form-item-control-wrapper .id_card-input-box,
.lot-remit-detail .lot-remit-detail-table .ant-form-item-control .id_card-input-box {
  width: 160px;
  text-align: center;
}
