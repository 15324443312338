.roster-detail {
  height: 100%;
  overflow-y: auto;
}
.roster-detail .container .bank-box {
  justify-content: flex-start;
}
.roster-detail .container .blue-txt {
  text-decoration: underline;
}
.roster-detail .container .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.roster-detail .container .title .txt {
  line-height: 28px;
  font-size: 16px;
  color: #444444;
  font-weight: 500;
  position: relative;
  padding-left: 18px;
}
.roster-detail .container .title .txt::before {
  content: '';
  width: 4px;
  height: 20px;
  background: #356CEA;
  position: absolute;
  left: 0;
  top: 4px;
}
.roster-detail .container .title .back-btn {
  width: 80px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  line-height: 32px;
  font-size: 12px;
  color: #444444;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.roster-detail .container .title .back-btn:hover {
  border-color: #356CEA;
  color: #356CEA;
}
.roster-detail .container .flex {
  display: flex;
  flex-direction: column;
}
.roster-detail .container .items {
  padding: 20px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 20px;
}
.roster-detail .container .items .label-box {
  font-size: 12px;
  color: #666666;
  line-height: 17px;
  margin-bottom: 10px;
}
.roster-detail .container .item {
  display: inline-block;
  width: 240px;
  margin-right: 14px;
  margin-bottom: 14px;
}
.roster-detail .container .item .value-box {
  height: 34px;
  background: #F8FAFB;
  border: 1px solid #E9ECF0;
  border-radius: 4px;
  padding-left: 14px;
  line-height: 32px;
  font-size: 12px;
  color: #AAAAAA;
}
.roster-detail .container .item .spe-box {
  width: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.roster-detail .container .item .spe-box .value-box {
  flex: 1;
  border-radius: 4px 0 0 4px;
}
.roster-detail .container .item .spe-box .edit-i {
  width: 40px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #E9ECF0;
  border-radius: 0 4px 4px 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: none;
}
.roster-detail .container .ant-table-wrapper {
  margin-top: 20px;
}
.upload-pic {
  width: 460px;
  background: #F9FAFC;
  border-radius: 4px;
  padding: 14px;
}
.upload-pic .txt {
  color: #aaa;
  font-size: 12px;
  margin-left: 14px;
  line-height: 32px;
}
.upload-pic .before-box,
.upload-pic .after-box {
  display: flex;
  align-items: center;
  width: 100%;
}
.upload-pic .after-box {
  justify-content: space-between;
}
.upload-pic .after-box img {
  cursor: pointer;
  margin-left: 14px;
}
.messageError {
  color: #FB4040;
  font-size: 14px;
}
@media screen and (max-width: 1440px) {
  .roster-detail .container .item-s {
    margin-right: 108px;
  }
}
.item-s {
  width: 330px;
  float: right;
  margin-right: 80px;
}
.item-s .label-box {
  position: relative;
  display: inline-block;
  font-size: 12px;
  color: #595959;
  width: 99px;
}
.item-s .label-xing::before {
  position: absolute;
  content: "*";
  left: -10px;
  width: 12px;
  color: #F5222D;
}
.item-s .idcard-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 330px;
}
.item-s .idcard-box .pic-box {
  width: 160px;
  height: 110px;
  position: relative;
}
.item-s .idcard-box .pic-box img {
  width: 160px;
  height: 110px;
}
.item-s .idcard-box .pic-box .action-pic {
  width: 160px;
  height: 110px;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(51, 51, 51, 0.6);
}
.item-s .idcard-box .pic-box .action-pic img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.item-s .idcard-box .pic-box .action-pic .remove {
  margin-left: 30px;
}
.item-s .idcard-box .pic {
  width: 160px;
  height: 110px;
  background-size: 160px 110px;
  flex-direction: column;
  cursor: pointer;
}
.item-s .idcard-box .pic .sb-l {
  width: 40px;
  height: 40px;
}
.item-s .idcard-box .pic span {
  font-size: 12px;
  color: #444;
  line-height: 17px;
  margin-top: 10px;
}
.item-s .idcard-box .pic span.uploadWarning {
  font-size: 12px;
  color: #999999;
  margin-top: 0;
}
.item-s .idcard-box .pic span.ant-spin-dot-spin {
  font-size: 20px;
}
.item-s .idcard-box .pic1 {
  background-image: url('https://imagesize.hrwork.com/sp/files/275880ff-4f98-4e41-9f7e-a9226c22ad45.png');
}
.item-s .idcard-box .pic2 {
  background-image: url('../../../../assets/IDBack.png');
}
.item-s .idcard-box .pic-border {
  border: 1px dashed #dadada;
  border-radius: 2px;
}
.item-s .idcard-box .editImage {
  position: absolute;
  right: -70px;
  bottom: 0;
  width: 60px;
  height: 24px;
  background: #FFFFFF;
  border: 1px solid #1890ff;
  border-radius: 12px;
  padding: 0 7px;
  color: #1890FF;
}
.item-s .idcard-box .editImage img {
  display: inline-block;
  vertical-align: inherit;
  margin-right: 5px;
}
.item-modal {
  width: 500px;
  display: flex;
  float: none;
  margin-right: 0;
}
