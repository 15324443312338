.create_new .ant-checkbox-wrapper {
  line-height: 17px;
  margin-left: 0;
  margin-right: 8px;
  height: 17px;
  margin-bottom: 18px;
}
.create_new .ant-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}
.create_new .ant-row .ant-form-item-label {
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  height: 17px;
  line-height: 17px;
}
.create_new .ant-row .ant-form-item-label label {
  font-size: 12px;
  color: #666666;
}
.create_new .ant-row .ant-form-item-required::before {
  display: none;
}
.create_new .ant-row .ant-form-item-control-wrapper {
  height: 34px;
}
.create_new .ant-row .ant-form-item-control {
  line-height: 34px;
}
.create_new .label-txt {
  font-size: 12px;
  color: #666666;
  margin-bottom: 14px;
}
