.layout-left {
  width: 160px;
  background: #363636;
}
.layout-left .ant-menu {
  background: #363636;
}
.layout-left .ant-menu .ant-badge {
  border: none;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.layout-left .ant-menu .ant-badge-count {
  border: none;
  box-shadow: none;
  text-align: left;
}
.layout-left .ant-menu .ant-menu-submenu-title {
  padding: 0 10px !important;
  margin: 0;
  line-height: 40px;
  font-size: 12px;
  color: #999999;
  font-weight: 400;
  background: #282828;
  width: 100%;
}
.layout-left .ant-menu .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.layout-left .ant-menu .ant-menu {
  background-color: #363636;
}
.layout-left .ant-menu .ant-menu-item {
  line-height: 40px;
  padding: 0 10px !important;
  margin: 0 10px;
  width: 140px;
  border-radius: 4px;
}
.layout-left .ant-menu .ant-menu-item .img-box {
  width: 13px;
  height: 13px;
  background-size: 13px 13px;
  margin-right: 10px;
}
.layout-left .ant-menu .ant-menu-item .lotRemit {
  background-image: url('https://imagesize.hrwork.com/sp/files/cb49f45e-383e-4d8c-a7f5-c54bda6ea7d2.png');
}
.layout-left .ant-menu .ant-menu-item .tradeOrder {
  background-image: url('https://imagesize.hrwork.com/sp/files/0cb8e063-2992-499c-9b1d-04f95e208673.png');
}
.layout-left .ant-menu .ant-menu-item .statisticlist {
  background-image: url('https://imagesize.hrwork.com/sp/files/07015ed5-fff6-4a95-acd1-b10e855c40a4.png');
}
.layout-left .ant-menu .ant-menu-item .funddetaillist {
  background-image: url('https://imagesize.hrwork.com/sp/files/a23222fb-32bb-4e2b-a5aa-038a9abce838.png');
}
.layout-left .ant-menu .ant-menu-item .application {
  background-image: url('https://imagesize.hrwork.com/sp/files/5782717b-1d34-46cb-917f-273b57a1b1f7.png');
}
.layout-left .ant-menu .ant-menu-item .record {
  background-image: url('https://imagesize.hrwork.com/sp/files/f7308598-6147-4cac-99fc-805e84aabde1.png');
}
.layout-left .ant-menu .ant-menu-item .info {
  background-image: url('https://imagesize.hrwork.com/sp/files/5c08b536-3645-4ea4-9079-6b61bbf9e2a4.png');
}
.layout-left .ant-menu .ant-menu-item .clientRosters {
  background-image: url('https://imagesize.hrwork.com/sp/files/05b6dede-b848-4a7d-b6b8-4ab547765438.png');
}
.layout-left .ant-menu .ant-menu-item .roster {
  background-image: url('https://imagesize.hrwork.com/sp/files/01e0b625-699c-4357-96e4-7f53440702df.png');
}
.layout-left .ant-menu .ant-menu-item .edeals {
  background-image: url('https://imagesize.hrwork.com/sp/files/bf3b7c1f-ffec-4b77-afef-f5a0888f5400.png');
}
.layout-left .ant-menu .ant-menu-item .financial {
  background-image: url('https://imagesize.hrwork.com/sp/files/0fbc966e-3a05-4bf4-b024-f40bc9d5d825.png');
}
.layout-left .ant-menu .ant-menu-item .account {
  background-image: url('https://imagesize.hrwork.com/sp/files/22959e81-170a-4008-b37a-5b2d485883c6.png');
}
.layout-left .ant-menu .ant-menu-item .role {
  background-image: url('https://imagesize.hrwork.com/sp/files/c36bd0e9-a896-443f-aba4-d018ba05db18.png');
}
.layout-left .ant-menu .ant-menu-item .manage {
  background-image: url('https://imagesize.hrwork.com/sp/files/b48381a1-b0e7-4fa7-9d52-fcbe695ae1eb.png');
}
.layout-left .ant-menu .ant-menu-item .review {
  background-image: url('https://imagesize.hrwork.com/sp/files/7fcc6619-7b8d-4708-a6d3-cd4e227b656b.png');
}
.layout-left .ant-menu .ant-menu-item .batch {
  background-image: url('https://imagesize.hrwork.com/sp/files/abf30894-0364-4c54-9c56-2d9cbf346f58.png');
}
.layout-left .ant-menu .ant-menu-item .riskBatch,
.layout-left .ant-menu .ant-menu-item .riskOrder {
  background-image: url('https://imagesize.hrwork.com/sp/files/ba58fff4-a4f9-4d74-bafc-10d64ff4bc14.png');
}
.layout-left .ant-menu .ant-menu-item .registerCancelOrder {
  background-image: url('https://imagesize.hrwork.com/sp/files/b872c6ac-bba8-48ee-a4b0-9a510e7fa741.png');
}
.layout-left .ant-menu .ant-menu-item:hover,
.layout-left .ant-menu .ant-menu-item-selected {
  background: #356CEA;
}
.layout-left .ant-menu .ant-menu-item:hover a,
.layout-left .ant-menu .ant-menu-item-selected a {
  color: #FFFFFF;
}
.layout-left .ant-menu .ant-menu-item:hover .lotRemit,
.layout-left .ant-menu .ant-menu-item-selected .lotRemit {
  background-image: url('https://imagesize.hrwork.com/sp/files/78b62634-557b-4768-8326-06d049ed7727.png');
}
.layout-left .ant-menu .ant-menu-item:hover .tradeOrder,
.layout-left .ant-menu .ant-menu-item-selected .tradeOrder {
  background-image: url('https://imagesize.hrwork.com/sp/files/df66c462-dd10-421d-bacc-5837b301b18c.png');
}
.layout-left .ant-menu .ant-menu-item:hover .statisticlist,
.layout-left .ant-menu .ant-menu-item-selected .statisticlist {
  background-image: url('https://imagesize.hrwork.com/sp/files/edb45fd3-baf0-4f0a-9737-de15da61f8d4.png');
}
.layout-left .ant-menu .ant-menu-item:hover .funddetaillist,
.layout-left .ant-menu .ant-menu-item-selected .funddetaillist {
  background-image: url('https://imagesize.hrwork.com/sp/files/1e94bc5f-226a-4430-8868-4316df13a3d3.png');
}
.layout-left .ant-menu .ant-menu-item:hover .application,
.layout-left .ant-menu .ant-menu-item-selected .application {
  background-image: url('https://imagesize.hrwork.com/sp/files/1d223212-5a5b-4d1f-8000-58ca928617d6.png');
}
.layout-left .ant-menu .ant-menu-item:hover .record,
.layout-left .ant-menu .ant-menu-item-selected .record {
  background-image: url('https://imagesize.hrwork.com/sp/files/883fb73b-da45-4bec-9cde-14e9a200d5e3.png');
}
.layout-left .ant-menu .ant-menu-item:hover .info,
.layout-left .ant-menu .ant-menu-item-selected .info {
  background-image: url('https://imagesize.hrwork.com/sp/files/455e7bff-1058-4386-a395-c1f223714570.png');
}
.layout-left .ant-menu .ant-menu-item:hover .clientRosters,
.layout-left .ant-menu .ant-menu-item-selected .clientRosters {
  background-image: url('https://imagesize.hrwork.com/sp/files/2a6c68ea-feb9-4cb0-98d0-6af19b5411e9.png');
}
.layout-left .ant-menu .ant-menu-item:hover .roster,
.layout-left .ant-menu .ant-menu-item-selected .roster {
  background-image: url('https://imagesize.hrwork.com/sp/files/b09dbdae-8903-4d6b-a528-ca53ff847262.png');
}
.layout-left .ant-menu .ant-menu-item:hover .edeals,
.layout-left .ant-menu .ant-menu-item-selected .edeals {
  background-image: url('https://imagesize.hrwork.com/sp/files/ff6e0219-a5fc-40aa-9d8a-444306e15b60.png');
}
.layout-left .ant-menu .ant-menu-item:hover .financial,
.layout-left .ant-menu .ant-menu-item-selected .financial {
  background-image: url('https://imagesize.hrwork.com/sp/files/6dde1e5e-0a8e-40d6-9286-0b20787efebe.png');
}
.layout-left .ant-menu .ant-menu-item:hover .account,
.layout-left .ant-menu .ant-menu-item-selected .account {
  background-image: url('https://imagesize.hrwork.com/sp/files/473064da-66c8-42e5-90ac-b47b8a917631.png');
}
.layout-left .ant-menu .ant-menu-item:hover .role,
.layout-left .ant-menu .ant-menu-item-selected .role {
  background-image: url('https://imagesize.hrwork.com/sp/files/cc551743-bd7e-4c41-88bb-d4d7524ea2da.png');
}
.layout-left .ant-menu .ant-menu-item:hover .manage,
.layout-left .ant-menu .ant-menu-item-selected .manage {
  background-image: url('https://imagesize.hrwork.com/sp/files/548af0b5-7bbf-4727-9cd0-b9664b61e590.png');
}
.layout-left .ant-menu .ant-menu-item:hover .review,
.layout-left .ant-menu .ant-menu-item-selected .review {
  background-image: url('https://imagesize.hrwork.com/sp/files/159227ba-3dc1-47f5-9640-19b29c7f3473.png');
}
.layout-left .ant-menu .ant-menu-item:hover .batch,
.layout-left .ant-menu .ant-menu-item-selected .batch {
  background-image: url('https://imagesize.hrwork.com/sp/files/cf847190-f599-4c68-bae4-1e521f4eadeb.png');
}
.layout-left .ant-menu .ant-menu-item:hover .riskBatch,
.layout-left .ant-menu .ant-menu-item-selected .riskBatch,
.layout-left .ant-menu .ant-menu-item:hover .riskOrder,
.layout-left .ant-menu .ant-menu-item-selected .riskOrder {
  background-image: url('https://imagesize.hrwork.com/sp/files/6289b9b1-52e6-4980-9ea4-b00c35fd49fc.png');
}
.layout-left .ant-menu .ant-menu-item:hover .registerCancelOrder,
.layout-left .ant-menu .ant-menu-item-selected .registerCancelOrder {
  background-image: url('https://imagesize.hrwork.com/sp/files/0b7d2594-fd17-4917-9174-2ad3bd42c426.png');
}
.layout-left .ant-menu .ant-menu-item-selected {
  background: #356CEA;
  font-size: 13px;
  color: #FFFFFF;
  font-weight: 400;
}
.layout-left .ant-menu .ant-menu-item-selected > a,
.layout-left .ant-menu .ant-menu-item-selected > a:hover {
  color: #FFFFFF !important;
}
.layout-left .ant-menu .ant-menu-item > a {
  font-size: 13px;
  color: #999999;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.layout-left .ant-menu .ant-menu-inline .ant-menu-item-selected::after {
  display: none;
}
