.sign_account .breadcrumb {
  margin-left: 4px;
  margin-bottom: 10px;
  font-size: 12px;
}
.sign_account .card1 {
  margin-bottom: 20px;
}
.sign_account .card1 .card-item {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}
.sign_account .card1 .card-title-14 {
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 10px;
}
.sign_account .card1 .card-text-14 {
  font-size: 14px;
  font-weight: bolder;
  color: rgba(0, 0, 0, 0.6);
}
.sign_account .card1 .card-num-24 {
  margin-top: 10px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
}
.sign_account .card1 .card-item-box {
  display: flex;
}
.sign_account .ant-steps-item-title {
  font-size: 14px;
}
.sign_account .tip_img {
  width: 14px;
  height: 14px;
  margin-left: 5px;
  margin-bottom: 2px;
  cursor: pointer;
}
