.tash-for-batch-detail {
  height: 100%;
  overflow-y: auto;
}
.tash-for-batch-detail .ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 10px 20px;
  height: 50px;
}
.tash-for-batch-detail .ant-table-wrapper .ant-table-tbody > tr > td input {
  padding-left: 9px;
  line-height: 28px;
}
.tash-for-batch-detail .ant-table-wrapper .ant-form-item,
.tash-for-batch-detail .ant-table-wrapper .ant-form-item-control-wrapper,
.tash-for-batch-detail .ant-table-wrapper .ant-form-item-control {
  min-height: 30px;
  line-height: 28px;
}
.tash-for-batch-detail .ant-table-wrapper .ant-form-item input,
.tash-for-batch-detail .ant-table-wrapper .ant-form-item-control-wrapper input,
.tash-for-batch-detail .ant-table-wrapper .ant-form-item-control input {
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 10px;
  text-align: left;
}
