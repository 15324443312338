.business-single-box .bs-status-box .title {
  padding: 0 6px;
}
.business-single-box .bs-status-box .bicon {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  display: inline-block;
}
.business-single-box .bs-status-box .bicon0 {
  background: #f9a123;
}
.business-single-box .bs-status-box .bicon1 {
  background: #3883f4;
}
.business-single-box .bs-status-box .bicon2 {
  background: #909194;
}
.business-single-box .bs-status-box .bicon3 {
  background: #45be6e;
}
.business-single-box .bs-status-box .bicon4 {
  background: #ff4d4f;
}
