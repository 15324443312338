.info-detail .ant-modal-footer {
  text-align: center;
}
.info-detail .title {
  white-space: pre-wrap;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.info-detail .type {
  color: #999999;
  font-size: 16px;
  text-align: center;
  padding-bottom: 20px;
}
.info-detail .content {
  margin-top: 20px;
}
.info-detail .content img {
  max-width: 100%;
}
.info-detail .content p {
  white-space: pre-wrap;
  line-height: unset;
  margin: 0;
  padding: 0;
  min-height: 1em;
  color: unset;
  font-size: 16px;
}
.info-detail .content table {
  width: 100%;
  table-layout: fixed;
  border-style: hidden;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #07a9fe;
  border-width: 0.5px;
  margin: 10px 0;
}
.info-detail .content table td {
  background-color: #fff;
  border: 1px solid #c5c5c5;
  border-width: 0.5px;
  overflow: visible;
  cursor: text;
  padding: 10px 15px;
  height: 41px;
}
.info-detail .file-box {
  margin-top: 50px;
}
.info-detail .file-box .file-title {
  height: 30px;
  background: #f2f2f2;
  border-radius: 5px;
  line-height: 30px;
  padding-left: 10px;
  color: #000000;
}
.info-detail .file-box .file-main {
  border: 1px solid #f2f2f2;
  border-top: none;
  border-radius: 5px;
  padding: 20px;
}
.info-detail .file-box .file-main .download-btn {
  cursor: pointer;
  margin-left: 10px;
  color: #1890ff;
}
.info-detail .file-box .file-main .file-item {
  margin-top: 10px;
}
