.lot-remit {
  height: 100%;
}
.lot-remit .breadcrumb {
  margin-left: 4px;
  margin-bottom: 10px;
  font-size: 12px;
}
.lot-remit .container .loading-items-box {
  height: 80px;
  background: #FFFFFF;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
}
.lot-remit .container .loading-items-box .anticon-close-circle {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.lot-remit .container .loading-items-box .loading-icon {
  width: 45px;
  height: 45px;
  background: #FFFFFF;
  border-radius: 20px;
  animation: spin 1s linear infinite;
}
.lot-remit .container .loading-items-box .c-txt {
  font-size: 12px;
  color: #444444;
  font-weight: 400;
  margin: 0 20px;
}
.lot-remit .container .loading-items-box .cxjs {
  width: 70px;
  line-height: 24px;
  background: #FFFFFF;
  border: 1px solid #356CEA;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  color: #356CEA;
  font-weight: 400;
}
.lot-remit .container .loading-items-box .cxpc {
  width: 70px;
  line-height: 24px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
  font-size: 12px;
  color: #666666;
  font-weight: 400;
}
.lot-remit .container .step {
  margin: 12px auto 30px;
  width: 704px;
}
.lot-remit .container .divider {
  margin: 20px 0;
}
.lot-remit .container .addNew {
  width: 100px;
  height: 34px;
  position: absolute;
  right: 0;
  bottom: 20px;
}
.lot-remit .container .add-box {
  display: flex;
}
.lot-remit .container .sign-tip {
  height: 32px;
  background-color: rgba(240, 70, 49, 0.1);
  border-radius: 3px;
  font-size: 12px;
  color: rgba(31, 56, 88, 0.8);
  line-height: 32px;
  flex: 1;
  margin-left: 10px;
  padding-left: 11px;
}
.lot-remit .container .sign-tip img {
  margin-right: 13px;
  margin-top: -2px;
}
.lot-remit .container .sign-tip a {
  color: rgba(0, 136, 255, 0.99);
  cursor: pointer;
}
.lot-remit .container .ant-pagination {
  text-align: right;
}
.lot-remit .container .uploading-box {
  margin-bottom: 20px;
  height: 232px;
  border: 1px solid #DADADA;
  font-size: 12px;
  color: rgba(16, 32, 53, 0.8);
}
.lot-remit .container .uploading-box .uploading-header {
  display: flex;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: rgba(31, 56, 88, 0.06);
}
.lot-remit .container .uploading-box .uploading-header p {
  flex-grow: 1;
}
.lot-remit .container .uploading-box .context {
  display: flex;
  height: calc(100% - 40px);
  flex-direction: column;
  justify-content: center;
}
.lot-remit .container .uploading-box .context .batch_name {
  color: rgba(16, 32, 53, 0.6);
}
.lot-remit .container .uploading-box .context .status {
  margin: 10px 0 20px;
  font-size: 16px;
}
