.update-mobile-box {
  width: 100%;
  height: calc(100vh - 80px);
  background: #FFFFFF;
  border-radius: 6px;
  padding: 10px;
}
.update-mobile-box .bg-box {
  background-image: linear-gradient(180deg, #F2F4F8 0%, rgba(242, 244, 248, 0) 98%);
  border-radius: 6px 6px 0 0px;
  background-size: 100% 300px;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding-top: 30px;
}
.update-mobile-box .bg-box .img-t {
  width: 1000px;
  height: 90px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.update-mobile-box .bg-box .b-box {
  width: 1000px;
  height: calc(100vh - 260px);
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  padding-top: 30px;
  margin: 20px auto 0 auto;
  position: relative;
}
.update-mobile-box .bg-box .b-box .btn-box {
  position: absolute;
  width: 50%;
  bottom: 20px;
  left: 25%;
  display: flex;
  justify-content: center;
}
.update-mobile-box .bg-box .b-box .btn-box .a {
  width: 90px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  color: #666666;
  font-weight: 500;
  line-height: 38px;
}
.update-mobile-box .bg-box .b-box .btn-box .b {
  width: 90px;
  height: 40px;
  background: #356CEA;
  border-radius: 4px;
  text-align: center;
  margin-left: 14px;
  padding: 0;
  cursor: pointer;
}
.update-mobile-box .bg-box .b-box .btn-box .b span {
  line-height: 38px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 500;
}
.update-mobile-box .bg-box .b-box .b-form-box {
  margin: 0 auto 14px auto;
  width: 460px;
}
.update-mobile-box .bg-box .b-box .b-form-box .ant-form-item-label {
  font-size: 12px;
  color: #666666;
  line-height: 17px;
  margin-bottom: 10px;
}
.update-mobile-box .bg-box .b-box .b-form-box .txt {
  width: 460px;
  height: 34px;
  background: #F8FAFB;
  border: 1px solid #E9ECF0;
  border-radius: 4px;
  padding: 0 14px;
  font-size: 12px;
  color: #AAAAAA;
  line-height: 32px;
}
.update-mobile-box .bg-box .b-box .b-form-box .ant-form-item-required::before {
  display: none;
}
.update-mobile-box .bg-box .b-box .b-form-box .input-box {
  display: flex;
  justify-content: space-between;
}
.update-mobile-box .bg-box .b-box .b-form-box .blue-action-btn {
  width: 80px;
  padding: 0;
  height: 34px;
}
.update-mobile-box .bg-box .b-box .b-form-box .blue-action-btn[disabled] span {
  color: #AAAAAA;
}
.update-mobile-box .bg-box .b-box .b-form-box .blue-action-btn span {
  line-height: 32px;
}
