.page-whiteMenu .table-operation {
  color: #1890ff;
}
.page-whiteMenu .table-operation span {
  cursor: pointer;
}
.look-label {
  color: #000000;
  opacity: 0.8;
  font-size: 12px;
  display: inline-block;
  width: 80px;
  margin-bottom: 12px;
  vertical-align: top;
}
.look-value {
  color: #000000;
  opacity: 0.6;
  font-size: 12px;
}
.look-img img {
  width: 100px;
  height: 100px;
  border-radius: 3px;
}
.small {
  width: 100px;
  height: 100px;
  border-radius: 3px;
  cursor: zoom-in;
}
