.result-payForSelfEmployed .ant-modal-content {
  width: 960px;
  height: 560px;
  background: #FFFFFF;
  border-radius: 4px;
}
.result-payForSelfEmployed .ant-modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.result-payForSelfEmployed .ant-modal-body .title {
  font-size: 20px;
  color: #444444;
  font-weight: 500;
  margin-bottom: 20px;
}
.result-payForSelfEmployed .ant-modal-body .p1,
.result-payForSelfEmployed .ant-modal-body .p2 {
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  margin-bottom: 20px;
}
.result-payForSelfEmployed .ant-modal-body .icon {
  margin-bottom: 40px;
}
.result-payForSelfEmployed .ant-modal-body .tip {
  margin-bottom: 20px;
}
.result-payForSelfEmployed .qwzczx {
  width: 170px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #356CEA;
  border-radius: 6px;
}
.result-payForSelfEmployed .qwzczx span {
  font-size: 16px;
  color: #356CEA;
  font-weight: 500;
}
