.right_account {
  height: 100%;
}
.right_account .breadcrumb {
  margin-left: 4px;
  margin-bottom: 10px;
  font-size: 12px;
}
.right_account .container .divider {
  margin: 20px 0;
}
.right_account .container .open_vip {
  margin-bottom: 12px;
}
.right_account .container .search-box {
  position: relative;
}
.right_account .container .search-box .open-account-btn {
  position: absolute;
  right: 0;
  bottom: 20px;
  width: 100px;
  height: 34px;
  border-radius: 4px;
}
.right_account .container .search-box .open-account-btn img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.right_account .forbid,
.right_account .normal {
  display: inline-table;
  vertical-align: middle;
  margin-right: 7px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.right_account .forbid {
  background-color: #D0021B;
}
.right_account .normal {
  background-color: #7CE008;
}
