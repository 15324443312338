.pengpai-tip {
  font-size: 12px;
  color: #FD4949;
  letter-spacing: 0;
  line-height: 22px;
  background-color: #EAF0FC;
  padding: 10px 20px;
  position: fixed;
  top: 50px;
  left: 160px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 999;
}
.pengpai-tip img {
  margin-right: 10px;
}
