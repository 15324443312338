.push-sign-modal .method-title {
  font-size: 12px;
  font-weight: bold;
  color: #000000;
}
.push-sign-modal .method-text {
  margin-top: 16px;
  font-size: 12px;
  color: #666666;
}
.push-sign-modal .method-text span {
  color: #0096FF;
  cursor: pointer;
  text-decoration: underline;
}
