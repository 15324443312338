.fund-account {
  height: 100%;
}
.fund-account .top-box {
  width: 100%;
  height: 160px;
  background: transparent;
  border-radius: 6px;
}
.fund-account .top-box .top-box-c {
  display: flex;
  width: 1720px;
  height: 160px;
}
.fund-account .top-box .item {
  width: 1080px;
  padding: 10px;
  display: flex;
  background-color: #fff;
}
.fund-account .top-box .item .l-box {
  width: 650px;
  height: 140px;
  background: #f9fbff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fund-account .top-box .item .l-box .txt-box {
  margin-left: 75px;
  display: flex;
  width: 400px;
}
.fund-account .top-box .item .l-box .txt-box .tt {
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  height: 37px;
  line-height: 37px;
  display: flex;
}
.fund-account .top-box .item .l-box .txt-box .tt .img-box {
  width: 12px;
  height: 12px;
  margin: 0 10px;
  cursor: pointer;
  position: relative;
}
.fund-account .top-box .item .l-box .txt-box .tt .img-box .img-pop {
  display: none;
  width: 255.5px;
  height: 37px;
  background-image: url('https://imagesize.hrwork.com/sp/files/0013423a-4279-4010-a6fe-81d19663bdfd.png');
  background-size: 255px 37px;
  text-align: center;
  color: #fff;
  position: absolute;
  right: -265px;
  top: 0;
}
.fund-account .top-box .item .l-box .txt-box .tt .img-box:hover .img-pop {
  display: block;
}
.fund-account .top-box .item .l-box .txt-box .p1 {
  width: 200px;
}
.fund-account .top-box .item .l-box .txt-box .p2 {
  width: 200px;
}
.fund-account .top-box .item .l-box .txt-box .bb {
  font-size: 20px;
  color: #444444;
  font-weight: 500;
}
.fund-account .top-box .item .l-box .txt-box .bb img {
  margin-right: 10px;
  border-radius: 4px;
}
.fund-account .top-box .item .jsd-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 20px;
  height: 140px;
}
.fund-account .top-box .item .jsd-box .txt {
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
}
.fund-account .top-box .item .jsd-box .js-b {
  display: flex;
}
.fund-account .top-box .item .jsd-box .js-b .ant-select-selection {
  background: #f8fafb;
  border: 1px solid #e9ecf0;
}
.fund-account .top-box .item .jsd-box .js-b .zzhk {
  width: 100px;
  height: 34px;
  border-radius: 4px;
  background-image: url('https://imagesize.hrwork.com/sp/files/89329054-d39c-46af-8c29-387546a17631.png');
  background-size: 100px 34px;
  cursor: pointer;
}
.fund-account .top-box .item2 {
  width: 620px;
  height: 160px;
  border-radius: 6px;
  background-size: 620px 160px;
  background-image: url('https://imagesize.hrwork.com/sp/files/3c4fbfd9-0a2e-436c-8082-f1cd715032db.png');
  padding-left: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}
.fund-account .top-box .item2 .t1 {
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 10px;
}
.fund-account .top-box .item2 .t2 {
  font-size: 12px;
  color: #ffffff;
  line-height: 25px;
  font-weight: 500;
}
.fund-account .sub-list {
  margin-top: 20px;
}
.fund-account .export-list-btn {
  right: 20px;
}
.fund-account .iconTui {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 10px;
  border-radius: 50%;
  background: red;
}
