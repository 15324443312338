.big_item {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.big_item:last-child {
  border: none;
}
.big_item .desc {
  width: 100%;
  font-size: 12px;
  margin: 7px 0;
}
.big_item p {
  font-size: 14px;
  line-height: 31px;
}
.big_item .item-c {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 50px;
}
.big_item .item_box {
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  margin: 7px 0;
}
.big_item .item_box.address .look_value {
  padding-right: 15px;
}
.big_item .item_box .look_title {
  display: inline-block;
  width: 85px;
  color: #000;
}
.big_item .item_box .look_value {
  display: inline-block;
  width: 190px;
  color: #666;
}
