.money-payForSelfEmployed .c-box {
  width: 460px;
  height: 180px;
  background: #F8FAFB;
  border-radius: 4px;
  padding: 20px 20px 17px 20px;
}
.money-payForSelfEmployed .item {
  display: flex;
  justify-content: space-between;
  line-height: 17px ;
  align-items: center;
  margin-bottom: 12px;
  font-size: 12px;
  color: #444444;
}
.money-payForSelfEmployed .item .label {
  line-height: 17px;
}
.money-payForSelfEmployed .item .label img {
  margin-left: 10px;
}
.money-payForSelfEmployed .item .label .smaller {
  width: 110px;
  height: 20px;
  border: 1px solid #DDDEDE;
  border-radius: 20px;
  margin-left: 10px;
  display: inline-flex;
}
.money-payForSelfEmployed .item .label .smaller img {
  margin: 0 7px 0 0;
}
.money-payForSelfEmployed .item .label .smaller2 {
  width: 60px;
  height: 20px;
  border: 1px solid #356CEA;
  border-radius: 20px;
  margin-left: 10px;
  display: inline-flex;
  color: #356CEA;
}
.money-payForSelfEmployed .item .value {
  font-weight: 500;
}
.pay-num-tip .ant-tooltip-inner {
  text-align: left;
  padding: 10px;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 25px;
  border-radius: 3px;
  width: 290px;
  height: 70px;
  background: #356CEA;
}
.pay-num-tip .ant-tooltip-arrow {
  border-right-color: #356CEA;
}
