.question-box {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #fff;
  padding: 10px;
}
.question-box .items-box {
  background-image: linear-gradient(180deg, #F2F4F8 0%, rgba(242, 244, 248, 0) 98%);
  border-radius: 6px 6px 0px 0px;
  background-size: 100% 300px;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.question-box .items-box .items {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding-top: 30px;
}
.question-box .items-box .items .cjwt {
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  line-height: 22px;
  padding-left: 16px;
  position: relative;
  margin-bottom: 20px;
}
.question-box .items-box .items .cjwt::before {
  content: '';
  width: 4px;
  height: 16px;
  background: #356CEA;
  position: absolute;
  left: 0;
  top: 3px;
}
.question-box .items-box .items .item-box {
  height: calc(100% - 42px);
}
.question-box .items-box .items .item {
  border-radius: 6px;
  border: 1px solid #eceef7;
  background-image: linear-gradient(90deg, #F2F4F8 1%, rgba(242, 244, 248, 0) 99%);
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-color: #fff;
  width: 1200px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
}
.question-box .items-box .items .item .left {
  width: 40px;
  height: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.question-box .items-box .items .item .right {
  line-height: 17px;
  font-size: 12px;
  background-color: #fff;
  padding: 20px 25px 20px 0;
  border-radius: 0 6px 6px 0;
}
.question-box .items-box .items .item .right .question {
  color: #444444;
  font-weight: bolder;
  margin-bottom: 10px;
}
.question-box .items-box .items .item .right .answer {
  color: #666666;
  font-weight: 400;
}
.question-box .items-box .items .item .right span {
  color: #444444;
  font-weight: bolder;
}
@media screen and (max-width: 1440px) {
  .question-box .items-box {
    padding-left: 20px;
    padding-right: 20px;
  }
  .question-box .items-box .items {
    width: 100%;
  }
  .question-box .items-box .items .item {
    width: 100%;
  }
}
