.initiate_page {
  padding: 35px;
  min-height: 650px;
  background: #fff;
}
.steps-action {
  margin: 30px 0 ;
  text-align: center;
}
.steps-action button {
  margin: 0 8px;
}
.step_one .top {
  font-size: 14px;
  line-height: 40px;
}
.step_one .top .til {
  width: 150px;
  display: inline-block;
  margin: 0;
}
.step_one .top span {
  margin: 0 10px;
}
.step_one .top button {
  margin-right: 10px;
}
.step_one .substract {
  padding: 30px 0;
}
.step_one .substract span {
  color: #000;
  margin-right: 30px;
}
.step_one .substract .blue {
  color: #1890ff;
}
.step_three {
  min-height: 400px;
}
.step_three .img_box {
  text-align: center;
}
.step_three .tip {
  text-align: center;
  margin: 20px 0 ;
}
.step_three .tip_l {
  text-align: center;
  padding-top: 80px ;
}
.mar-r {
  margin-right: 10px;
}
.next_confirm_box {
  padding-left: 40px;
}
.next_confirm_box p {
  line-height: 30px;
}
