.subTitle-task {
  border-left: 3px solid #1890ff;
  padding-left: 0.5em;
  line-height: 1;
  font-weight: bolder;
}
.giveBrokerage-tip {
  padding-left: 230px;
  color: #FE412E;
}
.give-brokerage-box .name-box {
  width: 460px;
  height: 34px;
  background: #F8FAFB;
  border: 1px solid #E9ECF0;
  border-radius: 4px;
  padding-left: 14px;
  line-height: 32px;
  font-size: 12px;
  color: #AAAAAA;
}
.give-brokerage-box .ant-form-item-required::before {
  display: none;
}
.give-brokerage-box .ant-form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}
.give-brokerage-box .ant-form-item .ant-form-item-label {
  margin-bottom: 10px;
  height: 17px;
  line-height: 17px;
  text-align: left;
}
.give-brokerage-box .ant-form-item .ant-form-item-label label {
  color: #666666;
}
.give-brokerage-box .ant-form-item-control {
  line-height: 34px;
}
.give-brokerage-box .template-box {
  width: 460px;
  background: #F8FAFB;
  border-radius: 4px;
  padding: 10px 10px 17px 10px;
}
.give-brokerage-box .template-box .txt-box {
  font-size: 12px;
  color: #444444;
  line-height: 25px;
  margin-bottom: 14px;
}
.give-brokerage-box .template-box .btn-box {
  display: flex;
}
.give-brokerage-box .template-box .btn-box .btn-btn {
  width: 80px;
  height: 34px;
  border: 1px solid #356CEA;
  border-radius: 4px;
  line-height: 32px;
  text-align: center;
  font-size: 12px;
  color: #356CEA;
  font-weight: 400;
  margin-right: 10px;
}
.give-brokerage-box .all-blue-btn {
  width: 70px;
  height: 34px;
  margin-left: 380px;
  margin-top: 20px;
  font-size: 14px;
}
