.account-info {
  font-size: 13px;
}
.account-info .account-info-title {
  width: 100px;
  text-align: right;
  margin-right: 10px;
}
.account-info .input {
  width: 300px;
}
.account-info .account-info-note {
  background: #eee;
  border-radius: 10px;
  padding: 15px 0;
  margin-top: 20px;
}
.account-info .account-info-note div {
  height: 20px;
  font-size: 12px;
  margin-left: 30px;
}
.account-info .account-info-note p {
  margin-left: 50px;
  line-height: 20px;
  font-size: 12px;
  margin-top: 8px;
  color: #666;
}
