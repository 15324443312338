.register-cancel-order .container .search-box {
  background-color: #fff;
  padding: 20px 20px 0px 20px;
  margin-bottom: 0;
}
.register-cancel-order .container .search-box .ant-form-inline .ant-form-item {
  margin-bottom: 20px;
}
.register-cancel-order .container .action a {
  color: #1890ff;
  font-size: 12px;
}
.register-cancel-order .back-to-main {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
}
.register-cancel-order .back-to-main i {
  font-size: 20px;
}
