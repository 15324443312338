.upload_attach .ant-radio-button-wrapper {
  margin-right: 10px;
  border-radius: 4px;
}
.upload_attach .ant-radio-button-wrapper ::before {
  background: none;
}
.upload_attach .ant-form {
  width: 100%;
  display: inline-block;
}
.upload_attach .upload-file {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
.upload_attach .upload-file > span {
  flex-shrink: 1;
  max-width: 37%;
}
.upload_attach .upload-file .lable {
  width: 80px;
  line-height: 34px;
}
.upload_attach .upload-file .ant-form-item {
  padding-bottom: 0;
  margin-bottom: 0;
}
.upload_attach .upload-file .file-rule {
  flex-shrink: 1;
  margin-left: 8px;
  color: rgba(31, 56, 88, 0.4);
}
.upload_attach .ant-form-item-required {
  display: inline-block;
  width: 80px;
}
