.roster_e_deals {
  height: 100%;
}
.roster_e_deals .like_a {
  color: #356CEA;
  cursor: pointer;
}
.roster_e_deals .topcard {
  display: flex;
  cursor: pointer;
}
.roster_e_deals .topcard .startsign {
  display: flex;
  flex: 1;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  border: 1px dashed #F37327;
  border-radius: 3px;
  font-size: 16px;
  color: #F37327;
}
.roster_e_deals .qrcode-esign {
  border: 1px solid #DADADA;
  margin-left: 10px;
  padding: 3px;
  border-radius: 3px;
  flex-shrink: 1;
  display: flex;
  width: 190px;
}
.roster_e_deals .qrcode-esign .img {
  aspect-ratio: 1;
  max-height: 100px;
  border-radius: 3px;
  border: solid 2px #DDE1E6;
  margin-right: 5px;
  flex-shrink: 0;
}
.roster_e_deals .qrcode-esign .info {
  padding: 10px 0;
  width: 80px;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.roster_e_deals .qrcode-esign .info a {
  text-decoration: underline;
}
.roster_e_deals .container .search-box {
  margin-bottom: 0;
}
.roster_e_deals .container .search-box .ant-form-inline .ant-form-item {
  margin-bottom: 20px;
}
.roster_e_deals .container .batch-send-box {
  position: absolute;
  right: 0;
  bottom: 20px;
  display: flex;
  align-items: center;
}
.roster_e_deals .container .batch-send-btn {
  width: 100px;
  height: 34px;
  border-radius: 4px;
  margin-left: 10px;
}
.roster_e_deals .container .action-btn {
  background: #FBFCFD;
}
.roster_e_deals .container .action-btn[disabled] {
  color: #CCCCCC;
}
.roster_e_deals .container .tip {
  color: red;
  margin-left: 20px;
  font-size: 12px;
}
.batch_repeat_send-tip .ant-tooltip-inner {
  width: 206px;
  height: 38px;
  background: #356CEA;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: 400;
  text-align: center;
  line-height: 26px;
}
.batch_repeat_send-tip .ant-tooltip-arrow {
  border-left-color: #356CEA;
}
@media screen and (max-width: 1440px) {
  .roster_e_deals .container .search-box {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .roster_e_deals .container .search-box .ant-form-inline .ant-form-item {
    margin-bottom: 20px;
  }
  .edeal-top-box .c-box {
    width: 1320px !important;
  }
  .edeal-top-box .c-box .ll {
    width: 800px !important;
  }
  .edeal-top-box .c-box .ll .left-box {
    width: 460px !important;
    flex-wrap: wrap;
    padding: 0 78px;
  }
  .edeal-top-box .c-box .ll .left-box .item-box {
    width: 140px;
  }
}
.sheh {
  width: 276px;
  height: 90px;
  background: #FFFFFF;
  box-shadow: 0px 2px 6px 0px #ececec;
  padding: 7px 15px 8px 9px;
  color: #444444;
  line-height: 25px;
  font-size: 12px;
}
.edeal-top-box {
  width: 100%;
  margin-bottom: 20px;
}
.edeal-top-box .c-box {
  width: 1720px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edeal-top-box .c-box .ll {
  width: 1200px;
  height: 180px;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 10px;
  display: flex;
}
.edeal-top-box .c-box .ll .left-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 840px;
  height: 160px;
  background: #F9FBFF;
  border-radius: 4px;
}
.edeal-top-box .c-box .ll .left-box .item-box {
  font-size: 12px;
  color: #666666;
}
.edeal-top-box .c-box .ll .left-box .item-box .mt10 {
  margin-top: 10px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.edeal-top-box .c-box .ll .left-box .item-box .mt10 span {
  font-size: 20px;
  color: #444444;
  font-weight: 500;
  margin-left: 10px;
}
.edeal-top-box .c-box .ll .right-box {
  margin-left: 76px;
  display: flex;
  align-items: center;
}
.edeal-top-box .c-box .ll .right-box .qrcode {
  width: 90px;
  height: 90px;
  border-radius: 6px;
  background-color: #EAF0FC;
  margin-right: 20px;
  padding: 5px;
}
.edeal-top-box .c-box .ll .right-box .txt-box .t {
  font-size: 16px;
}
.edeal-top-box .c-box .ll .right-box .txt-box .t img {
  margin-left: 10px;
}
.edeal-top-box .c-box .ll .right-box .txt-box .b {
  width: 80px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #356CEA;
  border-radius: 4px;
  font-size: 12px;
  color: #356CEA;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  line-height: 28px;
}
.edeal-top-box .c-box .rr {
  width: 500px;
  height: 180px;
  background-image: url('https://imagesize.hrwork.com/sp/files/d997ef17-1f0d-420e-9e21-d23ac8a02dcc.png');
  border-radius: 6px;
  background-size: 500px 180px;
  padding-left: 60px;
  padding-top: 70px;
}
.edeal-top-box .c-box .rr img {
  cursor: pointer;
  border-radius: 20px;
}
.ant-dropdown-menu-item {
  font-size: 12px;
}
.betch_export .count {
  color: #000;
  font-size: 14px;
}
.betch_export .ant-modal-footer {
  text-align: center;
  border-top: none;
}
.betch_export .ant-form-item {
  font-size: 12px;
  display: flex;
  margin-top: 12px;
}
#qrcodeContainer {
  position: fixed;
  left: -400px;
  top: -500px;
  z-index: -9999;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  width: 400px;
  height: 500px;
}
#qrcodeContainer .center-qr {
  margin: 0 auto;
}
#qrcodeContainer .header {
  height: 150px;
  background: #F2F8FE;
  text-align: center;
  padding-top: 30px;
}
#qrcodeContainer .header .title {
  font-size: 20px;
  margin-bottom: 20px;
}
#qrcodeContainer .header .btn {
  margin: 0 auto;
  font-size: 18px;
  color: white;
  line-height: 40px;
  width: 160px;
  border-radius: 20px;
  background-image: linear-gradient(to right, #1990FF, #36C3FF);
}
#qrcodeContainer .main {
  padding: 30px 0;
  border-bottom: 1px dashed #DDDDDD;
}
#qrcodeContainer .main .img-down {
  width: 205px;
  height: 205px;
  margin-bottom: 20px;
  position: relative;
  border: solid 10px #f2f8fe;
}
#qrcodeContainer .main .img-down::before {
  content: '';
  width: 40px;
  height: 50px;
  display: block;
  border: solid 5px #1990FF;
  border-right-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  top: -10px;
  left: -10px;
}
#qrcodeContainer .main .img-down::after {
  content: '';
  width: 40px;
  height: 50px;
  display: block;
  border: solid 5px #1990FF;
  border-top-color: transparent;
  border-left-color: transparent;
  position: absolute;
  bottom: -10px;
  right: -10px;
}
#qrcodeContainer .main .tip-qr {
  font-size: 15px;
  text-align: center;
}
#qrcodeContainer .bottom {
  font-size: 12px;
  padding-top: 10px;
  text-align: center;
}
