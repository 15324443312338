.outUl {
  display: flex;
  margin: 0;
  padding: 10px 0;
  border: 1px solid rgba(31, 56, 88, 0.15);
  border-radius: 3px;
}
.outUl li {
  position: relative;
  display: flex;
  padding-left: 20px;
  flex-grow: 1;
  width: 100%;
  list-style: none;
}
.outUl li .logoImg {
  margin-right: 20px;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background: #F6F6F6;
  text-align: center;
}
.outUl li .logoImg img {
  width: 20px;
  height: 20px;
  margin-top: 18px;
}
.outUl li .textInfo {
  width: -webkit-fill-available;
  padding-top: 2px;
  position: relative;
  font-size: 14px;
  color: #404D5D;
}
.outUl li .textInfo .peopleNum {
  position: absolute;
  bottom: 2px;
  color: #102035;
  font-weight: 600;
}
.outUl li .textInfo .peopleNum span {
  font-size: 18px;
}
.outUl li:after {
  position: absolute;
  top: 10%;
  right: 0;
  width: 1px;
  height: 80%;
  content: '';
  border-right: 1px solid #E9EFF4;
}
.outUl li:last-child:after {
  display: none;
}
.outUl .child_p {
  border-right: 1px solid #CED5DA;
  padding: 0 8px;
  font-size: 14px;
  color: #0078BD;
}
.outUl .child_p:last-child {
  border-right: none;
  padding-right: 0;
}
.outUl .child_p:first-child {
  padding-left: 0;
}
