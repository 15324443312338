.data-box .tt {
  width: 100%;
  height: 200px;
}
.data-box .tt .tt-c {
  width: 1720px;
  display: flex;
  justify-content: space-between;
}
.data-box .tt .ll {
  width: 1100px;
  height: 200px;
  background: #FFFFFF;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 10px;
}
.data-box .tt .ll img {
  margin-right: 60px;
}
.data-box .tt .ll .txt-box .t {
  font-size: 16px;
  color: #444444;
  font-weight: 500;
  margin-bottom: 40px;
}
.data-box .tt .ll .txt-box .b {
  display: flex;
}
.data-box .tt .ll .txt-box .b .item .txt {
  font-size: 12px;
  color: #666666;
  margin-bottom: 10px;
}
.data-box .tt .ll .txt-box .b .item .num {
  font-size: 20px;
  color: #444444;
  font-weight: 500;
}
.data-box .tt .ll .txt-box .b .item .num img {
  margin-right: 10px ;
}
.data-box .tt .ll .txt-box .b .item-m {
  margin: 0 120px;
}
.data-box .tt .rr {
  width: 600px;
  height: 200px;
}
.data-box .bb {
  margin: 20px 0;
}
.data-box .bb .title {
  font-size: 16px;
  color: #444444;
  font-weight: 500;
  line-height: 22px;
}
.data-box .bb .item-boxs {
  width: 1720px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.data-box .bb .item-box {
  width: 850px;
  height: 100px;
  background: #FFFFFF;
  border-radius: 6px;
  display: flex;
  margin-top: 20px;
  padding: 25px 30px;
}
.data-box .bb .item-box .ll {
  display: flex;
  width: 280px;
}
.data-box .bb .item-box .ll img {
  width: 50px;
  height: 50px;
  background: #F4F6FA;
  border-radius: 10px;
  margin-right: 14px;
}
.data-box .bb .item-box .ll .txt .txt1 {
  font-size: 12px;
  color: #BEBFC0;
  margin-bottom: 12px;
}
.data-box .bb .item-box .ll .txt .txt2 {
  font-size: 12px;
  color: #444444;
  font-weight: 500;
}
.data-box .bb .item-box .mm,
.data-box .bb .item-box .rr {
  width: 120px;
  height: 50px;
}
.data-box .bb .item-box .mm .t,
.data-box .bb .item-box .rr .t {
  font-size: 12px;
  color: #BEBFC0;
}
.data-box .bb .item-box .mm .t img,
.data-box .bb .item-box .rr .t img {
  margin-right: 6px;
}
.data-box .bb .item-box .mm .b,
.data-box .bb .item-box .rr .b {
  font-size: 12px;
  color: #444444;
  font-weight: 500;
  margin-top: 10px;
}
.data-box .bb .item-box .mm {
  margin: 0 130px 0 84px ;
}
