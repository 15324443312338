.table-checkPeople-pay {
  min-height: 100px;
}
.table-checkPeople-pay .ant-form {
  height: 34px;
}
.table-checkPeople-pay .ant-form .ant-form-item-control {
  line-height: 34px ;
}
.table-checkPeople-pay .btn-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.table-checkPeople-pay .ant-table-wrapper,
.table-checkPeople-pay .ant-spin-nested-loading {
  min-height: 100px;
}
.table-checkPeople-pay .ant-table-pagination {
  width: 100%;
  height: 30px;
  display: flex;
  margin-bottom: 0;
}
.table-checkPeople-pay .ant-table-pagination .ant-pagination-total-text {
  flex: 1;
}
.table-checkPeople-pay .ant-table-pagination .ant-pagination-total-text > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.table-checkPeople-pay .ant-table-thead > tr > th {
  background-color: #fff;
  padding: 8px 8px 8px 0;
  color: #444444;
  font-weight: 500;
  font-size: 12px;
}
.table-checkPeople-pay .ant-table-tbody > tr > td {
  padding: 8px 8px 8px 0;
  line-height: 17px;
  color: #666666;
  font-size: 12px;
}
.table-checkPeople-pay .ant-table-selection,
.table-checkPeople-pay .ant-table-selection-column {
  width: 16px !important;
  padding: 0 !important;
  text-align: left !important;
}
