.pageSalaryPage {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 100%;
  flex-direction: column;
  padding-top: 140px;
}
.pageSalaryPage .tip {
  color: #1f3858;
  opacity: 0.8;
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 20px;
}
.pageSalaryPage .littleTip {
  margin-bottom: 20px;
  color: #1f3858;
  opacity: 0.6;
  font-size: 14px;
}
.pageSalaryPage .salaryBtn {
  background-color: #F37327;
}
.pageSalaryPage .salaryBtn a {
  color: #ffffff;
}
.pageSalaryPage .salaryBtn:hover {
  color: #ffffff !important;
}
