.lot-remit {
  height: 100%;
}
.lot-remit .breadcrumb {
  margin-left: 4px;
  margin-bottom: 10px;
  font-size: 12px;
}
.lot-remit .container .step {
  margin: 12px auto 30px;
  width: 704px;
}
.lot-remit .container .divider {
  margin: 20px 0;
}
.lot-remit .container .ant-pagination {
  text-align: right;
}
.lot-remit .container .uploading-box {
  margin-bottom: 20px;
  height: 232px;
  border: 1px solid #DADADA;
  font-size: 12px;
  color: rgba(16, 32, 53, 0.8);
}
.lot-remit .container .uploading-box .uploading-header {
  display: flex;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: rgba(31, 56, 88, 0.06);
}
.lot-remit .container .uploading-box .uploading-header p {
  flex-grow: 1;
}
.lot-remit .container .uploading-box .context {
  display: flex;
  height: calc(100% - 40px);
  flex-direction: column;
  justify-content: center;
}
.lot-remit .container .uploading-box .context .batch_name {
  color: rgba(16, 32, 53, 0.6);
}
.lot-remit .container .uploading-box .context .status {
  margin: 10px 0 20px;
  font-size: 16px;
}
.ant-form-item {
  margin-bottom: 12px;
}
.add-new-modal .add-new-promit {
  color: #000;
}
.add-new-modal .ant-form label {
  font-size: 14px;
}
.add-new-modal .upload-file {
  display: flex;
  margin-top: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
.add-new-modal .upload-file .lable {
  width: 66px;
  line-height: 34px;
}
.add-new-modal .upload-file .file-rule {
  margin-top: 4px;
  color: rgba(31, 56, 88, 0.4);
}
.add-new-modal .ant-upload-list-item .anticon-close {
  right: 0;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-left: 0;
  font-size: 12px;
}
.allpay_detail {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
.allpay_detail .key {
  margin-bottom: 12px;
  width: 60px;
  color: rgba(0, 0, 0, 0.8);
}
.allpay_detail .remind {
  margin-bottom: 12px;
}
.allpay_detail .remind2 {
  margin-left: 12px;
}
.form_modal .ant-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.form_modal .ant-form-explain {
  font-size: 12px;
}
