.financial_control {
  width: 100%;
  height: 100%;
}
.financial_control .data-info {
  height: 100%;
}
.financial_control .search-box {
  position: relative;
}
.financial_control .new-money-box {
  width: 100%;
}
.financial_control .new-money-box .c-box {
  width: 1720px;
  display: flex;
  justify-content: space-between;
}
.financial_control .new-money-box .c-box .item-box {
  width: 560px;
  height: 100px;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 4px;
  display: flex;
}
.financial_control .new-money-box .c-box .item-box .ll,
.financial_control .new-money-box .c-box .item-box .rr {
  width: 276px;
  height: 92px;
  border-radius: 6px 0 0 6px;
  display: flex;
  padding: 21px 26px;
}
.financial_control .new-money-box .c-box .item-box .ll img,
.financial_control .new-money-box .c-box .item-box .rr img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.financial_control .new-money-box .c-box .item-box .ll .txt-box,
.financial_control .new-money-box .c-box .item-box .rr .txt-box {
  margin-left: 20px;
}
.financial_control .new-money-box .c-box .item-box .ll .txt-box .p1,
.financial_control .new-money-box .c-box .item-box .rr .txt-box .p1 {
  font-size: 12px;
  color: #BEBFC0;
  line-height: 17px;
  margin-bottom: 10px;
}
.financial_control .new-money-box .c-box .item-box .ll .txt-box .p2,
.financial_control .new-money-box .c-box .item-box .rr .txt-box .p2 {
  font-size: 18px;
  color: #444444;
  font-weight: 600;
  line-height: 25px;
}
.financial_control .new-money-box .c-box .item-box .ll {
  background: #F8FAFB;
}
.financial_control .ant-form-item {
  margin-right: 12px;
}
.financial_control .money-box {
  margin-top: 10px;
  background: #DEEFFA;
  border: 1px solid #A1D0EF;
  line-height: 36px;
  margin-bottom: 10px;
  font-size: 12px;
  overflow: scroll;
}
.financial_control .money-box .in-box {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.financial_control .money-box .t-box {
  width: 30px;
  font-weight: 500;
}
.financial_control .money-box .i {
  width: 100px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 26px;
}
.financial_control .money-box .i strong {
  font-weight: 500;
}
.financial_control-tip .ant-tooltip-inner {
  width: 276px;
  background: #356CEA;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: 400;
  text-align: center;
  line-height: 26px;
}
.financial_control-tip .ant-tooltip-arrow::before {
  background-color: #356CEA;
}
.financial_control-tip.ant-tooltip-placement-right .ant-tooltip-arrow,
.financial_control-tip.ant-tooltip-placement-rightBottom .ant-tooltip-arrow,
.financial_control-tip.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  border-right-color: #356cea;
}
