.login-page {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, rgba(53, 108, 234, 0) 0%, rgba(53, 108, 234, 0.04) 100%);
  position: relative;
}
.login-page .t-box {
  padding-top: 20px;
  height: 56px;
  display: flex;
  align-items: center;
  width: 1200px;
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -600px;
  z-index: 2;
}
.login-page .t-box span {
  font-size: 18px;
  color: #4e4e4e;
  letter-spacing: 2.57px;
  margin-left: 12px;
}
.login-page .gonggao-box {
  width: 1200px;
  padding-left: 20px;
  height: 83px;
  margin: 56px auto 0;
  background: linear-gradient(90deg, #f9fafc 0%, #e0ecfc 52%, #f9fafc 100%);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.login-page .gonggao-box .red {
  color: red;
}
.login-page .gonggao-box .shadow-box {
  text-align: center;
}
.login-page .gonggao-box .shadow-box .ggb-img {
  width: 29px;
  position: relative;
  top: 0;
  animation: ani-top 1.5s infinite ease-in-out;
}
.login-page .gonggao-box .shadow-box .ggb-img2 {
  width: 26px;
}
@keyframes ani-top {
  50% {
    top: 3px;
  }
}
.login-page .gonggao-box .ggb-content {
  padding-left: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #17233d;
  line-height: 22px;
}
.login-page .m-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 450px;
  width: 1200px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -600px;
}
.login-page .m-box .r {
  background: #ffffff;
  box-shadow: 0 4px 10px 0 rgba(53, 108, 234, 0.1);
  border-radius: 10px;
  width: 400px;
  min-height: 450px;
  position: relative;
}
.login-page .m-box .r::after {
  content: "";
  width: 105px;
  height: 80px;
  position: absolute;
  right: -40px;
  top: -45px;
  background-image: url("https://imagesize.hrwork.com/sp/files/438161e3-4a40-4480-9e6f-cb339eb5b6e3.png");
  background-size: 105px 80px;
  z-index: -999;
}
.login-page .m-box .r::before {
  content: "";
  width: 160px;
  height: 160px;
  position: absolute;
  right: -40px;
  bottom: -45px;
  background-image: url("https://imagesize.hrwork.com/sp/files/fffc8ef7-b618-43f7-9ec1-65424466dd3e.png");
  background-size: 160px 160px;
  z-index: -999;
}
.login-page .b-box {
  width: 1200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  color: #444444;
  background-image: linear-gradient(269deg, rgba(234, 240, 252, 0) 0%, #e5ecfc 50%, rgba(234, 240, 252, 0) 100%);
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left: -600px;
}
.login-page .b-box a:hover {
  text-decoration: none;
  color: #444444;
}
.login-page .bg-lj {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -600px;
  width: 1200px;
  height: 40px;
}
.login-page .bg-lj .lj {
  width: 160px;
  height: 300px;
  background-image: linear-gradient(180deg, rgba(53, 108, 234, 0) 0%, rgba(53, 108, 234, 0.1) 100%);
  position: absolute;
  left: -260px;
  bottom: 0;
}
.login-page .bg-lj img {
  position: absolute;
  left: -153px;
  bottom: 45px;
}
@media screen and (max-width: 1440px) {
  .login-page .bg-lj {
    display: none;
  }
}
@media screen and (max-width: 980px) {
  .login-page .m-box {
    justify-content: center;
  }
}
