.qrcodeDetailPage {
  background-color: white;
  padding: 20px;
}
.qrcodeDetailPage .top-qrcodeDetail .ant-form-item-control {
  line-height: 34px;
}
.qrcodeDetailPage .top-qrcodeDetail .sub-list-search-box {
  padding-left: 0;
}
.qrcodeDetailPage .companyName-qrcodeDetail {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  margin-bottom: 20px;
}
.qrcodeDetailPage .createDate {
  padding: 23px 0 20px 0;
  border-bottom: 1px solid #ECEEF7;
}
.photo-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.qrcode-remark {
  width: 200px;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.remark-pop .ant-tooltip-inner {
  width: 320px;
  background-color: #fff;
  color: #000;
  padding: 10px 10px 10px 12px;
}
