.ty-time {
  font-size: 12px;
  font-weight: normal;
  color: #BEBFC0;
}
.ty-message-dot-box {
  width: 12px;
}
.ty-message-dot {
  width: 5px;
  height: 5px;
  background: #f5222d;
  border-radius: 50%;
  margin-top: 5px;
}
.ty-message-box {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.ty-message-box .ty-content {
  color: #666;
  font-size: 12px;
  font-weight: normal;
  line-height: 17px;
  width: 241px;
}
.ty-message-box .ty-content a {
  font-weight: normal;
  color: #666;
  font-size: 13px;
}
.ty-message-close {
  width: 40px;
}
.ty-message-close img {
  width: 24px;
  height: 24px;
}
.ty-tab {
  height: 44px;
}
.ty-tab p {
  position: relative;
  height: 44px;
  text-align: center;
  line-height: 44px;
  padding: 0 20px;
  display: inline-block;
}
.ty-news-tab-selected {
  border-bottom: 2px solid #1890ff;
}
.ty-tab {
  width: 87px;
  position: relative;
}
.ty-tab .ty-count {
  height: 20px;
  min-width: 20px;
  position: absolute;
  border-radius: 20px;
  text-align: center;
  font-size: 10px;
  line-height: 18px;
  top: 1px;
  right: 3px;
  padding: 1px 2px;
  display: block;
  background: #f5222d;
  color: #fff;
}
.lxq-info-wrapper-box {
  height: 296px;
  overflow-y: auto;
}
.lxq-info-wrapper-box::nth-last-child(3) {
  border: none;
}
.lxq-info-wrapper-box .info-item {
  padding: 10px;
  border: none;
  border-top: 1px solid #F5F5F5;
  margin-bottom: 0;
  cursor: pointer;
  align-items: flex-start;
  border-radius: 0;
}
.lxq-info-wrapper-box .info-item:hover {
  background-color: #fff;
}
.lxq-info-wrapper-box .qqkk {
  font-size: 12px;
  color: #356CEA;
  font-weight: 400;
  cursor: pointer;
}
.lxq-info-wrapper-box .txt-box {
  display: flex;
  height: 17px;
}
.lxq-info-wrapper-box .ll-box {
  flex: 1;
  padding-right: 10px;
}
.lxq-info-drawer .ant-drawer-header {
  padding: 22px 0 10px 20px;
}
.ywxx-box #loadMore {
  color: #BEBFC0;
}
.ywxx-box .tt {
  width: 360px;
  height: 34px;
  background: #ECEEF7;
  padding: 0 10px;
  font-size: 12px;
  color: #444444;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ywxx-box .tt .ywxx-button {
  width: 60px;
  height: 20px;
  margin-left: 10px;
  border: 1px solid #356cea;
  border-radius: 11px;
  font-size: 12px;
  color: #356CEA;
  padding: 0;
}
.ywxx-box .ty-list-load-more {
  height: 17px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 9px;
}
.ywxx-box .ty-list-load-more img {
  margin-left: 10px;
}
