.had_upload_box .btn-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 71px;
}
.had_upload_box .ant-table-wrapper .ant-table-thead {
  height: 37px;
  border-bottom: 1px solid #eeeeee;
}
.had_upload_box .ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #fff;
  padding: 10px 40px 10px 0;
  font-size: 12px;
  color: #444444;
  font-weight: 500;
  line-height: 17px;
  height: 37px;
}
.had_upload_box .ant-table-tbody > tr > td {
  border: none;
}
.had_upload_box .ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 10px 40px 0 0;
}
.had_upload_box .ant-table-wrapper .ant-table-tbody > tr > td input {
  height: 30px;
  line-height: 28px;
  padding-left: 10px;
  padding-right: 10px;
}
.had_upload_box .batch-table {
  position: relative;
}
.had_upload_box .batch-table .ant-table-wrapper .ant-spin-nested-loading,
.had_upload_box .batch-table .ant-table-wrapper {
  min-height: 10px;
}
.had_upload_box .ant-pagination {
  padding-top: 20px;
}
.had_upload_box .sum-box {
  font-size: 12px;
  color: #bebfc0;
  line-height: 17px;
  display: inline-block;
  text-align: left;
  position: absolute;
  bottom: 24px;
  left: 0;
}
.had_upload_box .sum-box .blue {
  color: #356cea;
}
.had_upload_box .sum-box span {
  color: #666666;
}
.had_upload_box .sum-box .update-mobile-btn {
  width: 110px;
  height: 24px;
  border: 1px solid #356cea;
  border-radius: 17px;
  line-height: 22px;
  text-align: center;
  font-size: 12px;
  color: #356cea;
  cursor: pointer;
  margin-left: 10px;
  display: inline-block;
}
.had_upload_box1 {
  margin-top: 40px;
}
.file-info-box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  background: #f8fafb;
  border-radius: 4px;
  padding: 20px;
}
.file-info-box .file-info {
  flex: 1;
  padding: 0 20px;
}
.file-info-box .file-info p {
  font-size: 12px;
  color: #565656;
  font-weight: 400;
  line-height: 17px;
}
.file-info-box .file-info .file-size {
  margin-top: 6px;
  font-size: 12px;
  color: #7b7b7b;
  font-weight: 400;
}
.file-info-box .del-btn {
  cursor: pointer;
}
.batch-add-box {
  width: 560px;
  height: 120px;
  background: #f8fafb;
  border-radius: 4px;
  padding: 13px 14px;
}
.batch-add-box p {
  color: #444444;
  line-height: 25px;
  font-weight: 400;
  font-size: 12px;
}
.batch-add-box .ant-form-explain {
  margin-left: 90px;
}
.batch-add-box .down-template-btn,
.batch-add-box .upload-btn {
  width: 80px;
  height: 34px;
  border: 1px solid #356cea;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
}
.batch-add-box .upload-btn span,
.batch-add-box .down-template-btn {
  font-size: 12px;
  color: #356cea;
  font-weight: 600;
  text-align: center;
  line-height: 32px;
}
.batch-add-box .upload-btn {
  margin-left: 10px;
}
