.roster {
  height: 100%;
}
.roster .search-box .ant-form-inline .ant-form-item {
  margin-bottom: 20px;
}
.roster .search-box {
  padding-bottom: 0;
  margin-bottom: 0;
}
.roster .search-box .roster-add-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 120px;
  height: 34px;
  border-radius: 4px;
}
.roster .search-box .roster-add-btn img {
  margin-right: 10px;
}
.roster .ant-steps-item-title {
  font-size: 14px;
}
.roster .multiple_send_box {
  margin: 20px 0;
}
.roster .multiple_send_box .tip {
  color: red;
  margin-left: 20px;
  font-size: 12px;
}
.roster .btn-box {
  margin-top: 20px;
}
.roster .roster-table .ant-form-item,
.roster .roster-table .ant-form-item-control-wrapper,
.roster .roster-table .ant-form-item-control {
  min-height: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.roster .roster-table .ant-form-item input,
.roster .roster-table .ant-form-item-control-wrapper input,
.roster .roster-table .ant-form-item-control input {
  width: 102px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 4px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.roster .roster-table .action-box button {
  margin-right: 10px;
}
.roster-add {
  width: 120px;
  background: #FFFFFF;
  padding: 10px 0;
  border-radius: 4px;
}
.roster-add .btn {
  font-size: 12px;
  color: #444444;
  cursor: pointer;
  text-align: center;
  margin-bottom: 10px;
}
.roster-add .btn:last-child {
  margin-bottom: 0;
}
.roster-add .btn.active,
.roster-add .btn:hover {
  color: #356CEA;
}
.ant-form-extra {
  font-size: 12px;
}
.sbld {
  position: absolute;
  left: 50%;
  bottom: -60px;
  min-width: 300px !important;
  height: 50px;
  line-height: 15px;
  text-align: center;
  background-color: #fff;
  transform: translateX(-50%);
  border-radius: 5px;
  padding: 0 12px;
  display: none;
  z-index: 999999;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.sbld::before {
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  border-top: 8px transparent dashed;
  border-left: 8px transparent dashed;
  border-bottom: 8px #fff dashed;
  border-right: 8px transparent solid;
}
.sbsj {
  position: relative;
}
.sbsj .error-input {
  border-color: red;
  background-color: rgba(240, 70, 29, 0.1);
}
.sbsj:hover .sbld {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ml16 {
  margin-left: 16px;
}
@media screen and (max-width: 1440px) {
  .roster .search-box {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .roster .search-box .roster-add-btn {
    bottom: 20px;
  }
}
