.ticket-info-edit-box {
  height: 100%;
}
.ticket-info-edit-box .top {
  width: 100%;
  height: 22px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ticket-info-edit-box .top .pre-box {
  width: 4px;
  height: 16px;
  background: #356cea;
  margin-right: 12px;
}
.ticket-info-edit-box .top .txt {
  font-size: 16px;
  color: #444444;
  font-weight: 600;
}
.ticket-info-edit-box .bottom {
  width: 100%;
  min-height: calc(100vh - 130px);
  border: 4px solid #fff;
  border-radius: 6px;
  margin-top: 12px;
  position: relative;
  background-color: #fff;
}
.ticket-info-edit-box .bottom form {
  padding: 12px 0 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.ticket-info-edit-box .bottom .shhheh,
.ticket-info-edit-box .bottom .sblbs {
  width: 80px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid #356cea;
}
.ticket-info-edit-box .bottom .shhheh span,
.ticket-info-edit-box .bottom .sblbs span {
  font-size: 14px;
  font-weight: 500;
}
.ticket-info-edit-box .bottom .shhheh {
  margin: 6px 0 0 130px;
}
.ticket-info-edit-box .bottom .shhheh span {
  font-size: 14px;
  color: #356cea;
  font-weight: 500;
}
.ticket-info-edit-box .bottom .sblbs {
  background: #356cea;
  margin: 12px 0 0 130px;
}
.ticket-info-edit-box .bottom .sblbs span {
  color: #fff;
}
.ticket-info-edit-box .bottom::before {
  content: '';
  width: 110px;
  height: 100%;
  background: #f8fafb;
  border-radius: 6px 0px 0px 6px;
  position: absolute;
  left: 0;
  top: 0;
}
.ticket-info-edit-box .bottom .ant-form-item {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}
.ticket-info-edit-box .bottom .ant-form-item-label {
  background: #f8fafb;
  width: 110px;
  padding-right: 20px;
  color: #444444;
  font-weight: 500;
  height: 34px;
  line-height: 34px;
}
.ticket-info-edit-box .bottom .ant-form-item-label label {
  text-align: right;
  width: 90px;
  display: inline-block;
  padding: 0;
}
.ticket-info-edit-box .bottom .ant-form-item-label label:after {
  display: none;
}
.ticket-info-edit-box .bottom .ant-form-item-required::before {
  display: inline-block !important;
}
.ticket-info-edit-box .bottom .ant-form-item-control-wrapper {
  padding-left: 20px;
  line-height: 34px;
}
.ticket-info-edit-box .bottom .ant-form-item-control {
  line-height: 34px;
}
.ticket-info-edit-box .bottom .value {
  font-size: 12px;
  color: #666666;
  width: 460px;
}
