.account-info-modal .ant-modal-body {
  padding: 20px 20px 150px 20px;
  position: relative;
}
.account-info-modal .item {
  width: 460px;
  margin-bottom: 14px;
}
.account-info-modal .item .title {
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 10px;
}
.account-info-modal .item .ant-select-selection--single {
  height: 34px;
}
.account-info-modal .item .value-box {
  background: #F8FAFB;
  width: 460px;
  height: 34px;
  line-height: 32px;
  border: 1px solid #E9ECF0;
  border-radius: 4px;
  padding: 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #AAAAAA;
  font-weight: 400;
}
.account-info-modal .item .value-box img {
  cursor: pointer;
}
.account-info-modal .lianhao {
  display: flex;
  justify-content: space-between;
}
.account-info-modal .lianhao .box {
  width: 220px;
}
.account-info-modal .lianhao .box .value-box {
  width: 220px;
}
.account-info-modal .account-note {
  width: 500px;
  height: 140px;
  background: #F8FAFB;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 20px;
  border-radius: 0 0 4px 4px;
}
.account-info-modal .account-note div {
  height: 17px;
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #444444;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 10px;
}
.account-info-modal .account-note p {
  font-size: 12px;
  color: #444444;
  font-weight: 400;
  line-height: 25px;
}
