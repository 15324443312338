body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td,
object {
  padding: 0;
  border: none;
  margin: 0;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0;
  margin-right: 0;
  padding-left: 0;
  border: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
input,
textarea {
  outline: medium;
}
ol,
ul,
li {
  list-style: none;
}
i {
  font-style: normal;
}
img {
  border: none;
}
.bg-line {
  background: -webkit-linear-gradient(left, #0472FF, #68AFFE);
  background: -moz-linear-gradient(left, #0472FF, #68AFFE);
  background: linear-gradient(left, #0472FF, #68AFFE);
}
.border-line {
  border-bottom: 2px solid #68AFFE;
  /* border-image: -moz-linear-gradient(to right, #0472FF , #68AFFE);
    border-image: linear-gradient(to right, #0472FF , #68AFFE);
    border-image-slice: 10;
    border-bottom: 2px solid transparent; */
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: inline-block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.point {
  cursor: pointer;
}
.clearfix {
  *zoom: 1;
}
.point {
  cursor: pointer;
}
i {
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
}
a {
  color: #000;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
html {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
textarea {
  resize: none;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.hide {
  display: none;
}
.block {
  display: block;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.to-wrap {
  word-break: break-all;
  word-wrap: break-word;
  width: 100%;
}
.lm {
  text-decoration: line-through;
}
.flex-wrap {
  flex-wrap: wrap;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
}
/* 子元素-平均分栏 */
.flex-1 {
  -webkit-box-flex: 1;
  /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1;
  /* OLD - Firefox 19- */
  -webkit-flex: 1;
  /* Chrome */
  -ms-flex: 1;
  /* IE 10 */
  flex: 1;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}
.flex-2 {
  -webkit-box-flex: 2;
  /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 2;
  /* OLD - Firefox 19- */
  -webkit-flex: 2;
  /* Chrome */
  -ms-flex: 2;
  /* IE 10 */
  flex: 2;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}
/* 父元素-横向排列（主轴） */
.flex-x {
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  display: box;
  /* OLD - Android 4.4- */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  /* 09版 */
  -webkit-box-orient: horizontal;
  /* 12版 */
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;
}
/* 主轴居中 */
.flex-mc {
  /* 09版 */
  -webkit-box-pack: center;
  /* 12版 */
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  /* 其它取值如下：
    align-items     主轴原点方向对齐
    flex-end        主轴延伸方向对齐
    space-between   等间距排列，首尾不留白
    space-around    等间距排列，首尾留白
   */
}
/* 父元素-纵向排列（主轴） */
.flex-y {
  display: box;
  /* OLD - Android 4.4- */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  /* 09版 */
  -webkit-box-orient: vertical;
  /* 12版 */
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}
/* 副轴居中 */
.flex-sc {
  /* 09版 */
  -webkit-box-align: center;
  /* 12版 */
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}
.a-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.m-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.page {
  width: 100%;
  height: 100%;
}
.show {
  display: block;
}
.hide {
  display: none;
}
.hover-lay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: #fff;
  opacity: 0.2;
  width: 100%;
  height: 100%;
}
.center {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
}
.btn-line {
  color: #1890ff;
  cursor: pointer;
  font-size: 12px;
}
.btn-line + .btn-line {
  margin-left: 15px;
}
