.to_sign {
  position: relative;
}
.to_sign .ant-form-vertical .ant-form-item {
  padding-bottom: 0;
  margin-bottom: 14px;
}
.to_sign .ant-form-vertical .ant-form-item:last-child {
  margin-bottom: 0;
}
.to_sign .bottom-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.to_sign .ant-modal-footer {
  padding-top: 20px;
}
.to_sign .bottom-txt {
  width: 500px;
  height: 80px;
  background: #F8FAFB;
  border-radius: 0 0 4px 4px;
  padding-top: 15px;
  padding-left: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.to_sign .bottom-txt p {
  font-size: 12px;
  color: #444444;
  line-height: 25px;
}
.to_sign .yulanxieyi {
  width: 80px;
  height: 34px;
  border-radius: 4px;
  margin-left: 10px;
  border: 1px solid #356CEA;
}
.to_sign .yulanxieyi[disabled] {
  background: #F8FAFB;
  border: 1px solid #E9ECF0;
}
.to_sign .yulanxieyi[disabled] span {
  color: #AAAAAA;
}
.to_sign .yulanxieyi span {
  color: #356CEA;
}
