.standpage {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
}
.standpage .stamp-standpage {
  position: absolute;
  right: 30px;
  top: 30px;
}
.standpage .main-standpage {
  flex: 1;
}
.standpage .title-standpage {
  font-size: 18px;
  margin-bottom: 30px;
}
.standpage .title-standpage::after {
  content: '';
  display: block;
  background: #FF9F00;
  width: 1.6em;
  height: 2px;
}
.standpage .pagiNation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
}
.standpage .sub-list .ant-tabs-tab {
  width: 130px;
}
.standpage .sub-list .ant-tabs-ink-bar {
  margin-left: 55px;
}
.standpage .search-box {
  margin-bottom: 0;
}
