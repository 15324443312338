.itemForDetail {
  margin-bottom: 14px;
}
.itemForDetail .label {
  font-size: 14px;
  color: #AAAAAA;
  line-height: 20px;
  margin-bottom: 10px;
}
.itemForDetail .img-boxs {
  display: flex;
  justify-content: space-between;
}
.itemForDetail .img-boxs .img-box {
  position: relative;
}
.itemForDetail .img-boxs .img-c {
  border-radius: 4px;
  width: 220px;
  height: 140px;
}
.itemForDetail .fail-img-boxs {
  width: 460px;
  height: 140px;
  border: 1px solid #E9ECF0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemForDetail .fail-img-boxs img {
  width: 100px;
  height: 100px;
}
.itemForDetail .fail-img-boxs span {
  font-size: 12px;
  color: #666666;
  margin-left: 20px;
}
.itemForDetail .content {
  font-size: 14px;
  color: #8C8D8D;
  width: 460px;
  height: 44px;
  background: #F8FAFB;
  border: 1px solid #E9ECF0;
  border-radius: 4px;
  padding-left: 13px;
  line-height: 42px;
}
.itemForDetail .opacity {
  opacity: 0;
  position: fixed;
  z-index: -999;
}
.itemForDetail .loadingContainer {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemForDetail .mask-box {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.6);
}
.itemForDetail .zoomInBtn-container {
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.itemForDetail .zoomInBtn-container img {
  width: 14px;
  height: 14px;
}
.itemForDetail .zoomInBtn {
  font-size: 30px;
  color: white;
}
.itemForDetail .errtip {
  font-size: 12px;
  font-style: italic;
}
.itemForDetail img {
  max-width: 300px;
  max-height: 300px;
}
.itemForDetail .file-box {
  width: 460px;
  height: 90px;
  background: #F8FAFB;
  border: 1px solid #E9ECF0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 19px;
}
.itemForDetail .file-box .txt {
  flex: 1;
  font-size: 14px;
  margin: 0 14px;
  color: #356CEA;
}
.bigImg-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}
.bigImg-container img {
  max-width: 80%;
  max-height: 80%;
}
