.export-order-detail .ant-modal-title {
  font-size: 20px;
  line-height: 28px;
}
.export-order-detail .ant-modal-close {
  right: 0;
  top: 0;
}
.export-order-detail .ant-modal-close i {
  display: none;
}
.export-order-detail .ant-modal-close .ant-modal-close-x {
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('https://imagesize.hrwork.com/sp/files/3414c8ae-673b-4498-8e03-8eecd1dbba28.png');
}
.export-order-detail .btn-contr {
  height: 80px;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.export-order-detail .btn-contr .clear {
  font-size: 12px;
  color: #356CEA;
  font-weight: 400;
  text-decoration: underline;
  margin-left: 20px;
  cursor: pointer;
}
.export-order-detail .btn-contr button {
  width: 70px;
  height: 40px;
  background: #356CEA;
  border-radius: 4px;
}
.export-order-detail .btn-contr button span {
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 500;
}
.export-order-detail .ant-modal-body {
  padding: 0 20px;
}
.export-order-detail .export-order-detail-box {
  width: 460px;
  height: 360px;
  background: #F8FAFB;
  border-radius: 4px;
  padding: 20px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.export-order-detail .export-order-detail-box .exportName {
  display: inline-block;
  width: 140px;
  margin-bottom: 21px;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  color: #444444;
}
.export-order-detail .export-order-detail-box .exportName .checkBox {
  margin-right: 10px;
  line-height: 16px;
}
