.performance_manage .container a,
.performance_manage .container .table-action {
  color: #1890ff;
}
.performance_manage .container .divider {
  margin: 20px 0;
}
.performance_manage .company_title {
  padding-left: 4px;
  margin-bottom: 22px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
.performance_manage .performance_title {
  margin-left: -6px;
  margin-bottom: -12px;
  padding-left: 8px;
  border-left: 2px solid #F37327;
  font-weight: bold;
  font-size: 16px;
}
.performance_manage .detail_title {
  margin-top: 20px;
}
.performance_manage .upload_adjunct {
  display: flex;
  margin-bottom: 12px;
}
.performance_manage .promit {
  margin-left: 13px;
  font-size: 12px;
  color: rgba(31, 56, 88, 0.4);
}
.performance_manage .container {
  height: calc(100% - 83px);
}
.performance_manage .container .table-action {
  font-size: 12px;
}
