.add-new-modal-box .label {
  line-height: 17px;
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  margin-bottom: 10px;
}
.add-new-modal-box .ant-modal-content {
  min-height: 482px;
}
.add-new-modal-box .ant-modal-content .ant-form-item {
  padding-bottom: 0;
  margin-bottom: 14px;
}
.add-new-modal-box .upload-box {
  width: 460px;
  background: #F8FAFB;
  border-radius: 4px;
  padding: 10px 14px;
}
.add-new-modal-box .upload-box p {
  font-size: 12px;
  color: #444444;
  line-height: 25px;
  font-weight: 400;
  margin: 0;
}
.add-new-modal-box .upload-box .btn-box {
  display: flex;
  margin-top: 10px;
}
.add-new-modal-box .upload-box .btn-box .ant-btn {
  width: 80px;
  height: 34px;
  border: 1px solid #356CEA;
  border-radius: 4px;
  margin-right: 10px;
}
.add-new-modal-box .upload-box .btn-box .ant-btn:hover {
  border: 1px solid #356CEA;
  color: #356CEA;
}
.add-new-modal-box .upload-box .btn-box .ant-btn span {
  color: #356CEA;
}
.add-new-modal-box .file-info-box {
  display: flex;
  align-items: center;
  width: 460px;
  height: 80px;
  background: #F8FAFB;
  border-radius: 4px;
  padding: 20px;
}
.add-new-modal-box .file-info-box .file-info {
  flex: 1;
  padding: 0 20px;
}
.add-new-modal-box .file-info-box .file-info p {
  font-size: 12px;
  color: #565656;
  font-weight: 400;
  line-height: 17px;
}
.add-new-modal-box .file-info-box .file-info .file-size {
  margin-top: 6px;
  font-size: 12px;
  color: #7B7B7B;
  font-weight: 400;
}
.add-new-modal-box .file-info-box .del-btn {
  cursor: pointer;
}
.add-new-modal-box .tip-ziyoufuwuzhe {
  font-size: 12px;
  margin-top: 20px;
}
.add-new-modal-box .tip-ziyoufuwuzhe .ant-checkbox-wrapper {
  margin-right: 5px;
}
.add-new-modal-box .tip-ziyoufuwuzhe a {
  color: #2B87FF;
  text-decoration: underline;
}
