.client_roster {
  height: 100%;
}
.client_roster .tab-box {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 20px;
  display: flex;
}
.client_roster .tab-box .box {
  line-height: 20px;
  font-size: 14px;
  color: #444444;
  font-weight: 500;
  position: relative;
  margin-right: 40px;
  text-align: center;
  cursor: pointer;
}
.client_roster .tab-box .box::before {
  content: '';
  width: 20px;
  height: 3px;
  background: transparent;
  position: absolute;
  left: 50%;
  bottom: -20px;
  margin-left: -10px;
}
.client_roster .tab-box .box.active {
  color: #356CEA;
}
.client_roster .tab-box .box.active::before {
  background: #356CEA;
}
