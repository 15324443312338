@import '~antd/dist/antd.css';
* {
  box-sizing: border-box;
}
html,
body,
div,
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}
html,
body,
.zyz-wrapper {
  width: 100%;
  height: 100%;
}
.inner-box {
  width: 1200px;
  margin: 0 auto;
}
.layout-right {
  width: calc(100% - 160px);
  flex: 1;
  padding: 10px 20px 20px 20px;
  overflow-x: hidden;
  background: rgba(31, 56, 88, 0.06);
  overflow: hidden;
}
.mb10 {
  margin-bottom: 10px;
}
.main-content {
  height: calc(100vh - 60px);
  display: flex;
}
.flex {
  display: flex;
}
.ac {
  align-items: center;
}
.jsb {
  justify-content: space-between;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pointer {
  cursor: pointer;
}
.word-break {
  word-break: break-all;
}
.ant-form-item-label label::after {
  content: '';
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none;
}
.ant-breadcrumb,
.ant-steps .ant-steps-item-title,
.ant-btn,
.ant-input,
.ant-form label,
.ant-select,
.ant-table {
  font-size: 12px;
}
.tc {
  text-align: center;
}
.color-blue,
.blue {
  color: #356CEA !important;
}
.color-red {
  color: #FF0C0C !important;
}
.ant-btn-primary {
  background-color: #356CEA;
  border-color: #356CEA;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #356CEA;
  border-color: #356CEA;
}
.action-box {
  display: flex;
}
.action-box .action-btn {
  margin-right: 10px;
}
.search-box {
  position: relative;
}
.search-box .right-ds {
  margin-right: 14px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.search-box .right-ds .search-btn,
.search-box .right-ds .export-btn {
  margin-right: 0;
}
.search-box p {
  font-size: 12px;
  line-height: 17px;
  height: 27px;
}
.search-box .export-list-btn {
  position: absolute;
  right: 0;
  bottom: 20px;
}
.search-box .export-list-btn button {
  width: 100px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #356CEA;
  border-radius: 4px;
  padding: 0;
  line-height: 32px;
}
.search-box .export-list-btn button span {
  font-size: 12px;
  color: #356CEA;
  font-weight: 500;
  margin-left: 10px;
}
.search-box .download-box {
  position: absolute;
  right: 0;
  bottom: 20px;
}
.search-box .download-box .ant-btn {
  height: 34px;
  background: #356CEA;
  border-radius: 4px;
  padding: 0 14px;
}
.search-box .download-box .ant-btn span {
  color: #fff;
  margin-left: 10px;
}
.search-box .download-box .ant-btn:hover {
  background: #356CEA;
}
.search-box .download-box .dzhd-btn button {
  width: 120px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
}
.search-box .download-box .dzhd-btn button span {
  color: #D9D9D9;
  font-weight: 500;
}
.search-box .ant-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  position: relative;
  width: 100%;
}
.search-box .ant-form .ant-form-item-control {
  line-height: 32px;
}
.search-box .ant-form-item {
  display: inline-block;
  margin-right: 14px;
  margin-bottom: 20px;
}
.search-box .ant-form-item .search-btn,
.search-box .ant-form-item .export-btn {
  margin-right: 0;
}
.search-box .ant-form-item > .ant-form-item-label {
  display: block;
  text-align: left;
  line-height: 17px;
  padding-bottom: 10px;
}
.search-box .ant-form-item > .ant-form-item-label label {
  font-size: 12px;
  color: #444444;
  font-weight: 400;
}
.search-box .ant-select-selection,
.search-box .ant-calendar-picker-input,
.search-box .ant-input {
  border: 1px solid #fff;
}
.search-box .ant-select-selection:focus,
.search-box .ant-calendar-picker-input:focus,
.search-box .ant-input:focus {
  border-color: #356CEA;
  box-shadow: none;
}
.search-box .ant-select-selection:hover,
.search-box .ant-calendar-picker-input:hover,
.search-box .ant-input:hover {
  border-color: #356CEA;
}
.search-box .ant-select-selection:active,
.search-box .ant-calendar-picker-input:active,
.search-box .ant-input:active {
  box-shadow: none;
}
.search-box .ant-btn {
  height: 34px;
  line-height: 32px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}
.search-box .search-btn,
.search-box .export-btn {
  margin-right: 14px;
}
.search-box .reset-btn,
.search-box .export-btn {
  width: 70px;
}
.sub-list-search-box {
  background-color: #fff;
  padding: 20px 20px 0 20px;
  margin: 0;
}
.sub-list-search-box .ant-calendar-picker-input,
.sub-list-search-box .ant-input,
.sub-list-search-box .ant-select-selection {
  border: 1px solid #ECEEF7;
}
.sub-list-search-box .ant-calendar-picker-input:hover,
.sub-list-search-box .ant-input:hover,
.sub-list-search-box .ant-select-selection:hover {
  border-color: #356CEA;
  border-right-width: 1px !important;
}
.sub-list-search-box .download-box {
  bottom: 20px;
}
.normal-btn:hover,
.normal-btn:focus,
.normal-btn:active {
  background-color: #fff;
  border-color: #356CEA;
  color: #356CEA;
}
.search-btn {
  border-color: #356CEA;
  color: #356CEA;
  width: 70px;
}
.search-btn:hover,
.search-btn:focus {
  background: #356CEA;
  border-color: #356CEA;
  color: #fff;
}
.reset-btn,
.export-btn {
  width: 70px;
}
.reset-btn:hover,
.export-btn:hover,
.reset-btn:focus,
.export-btn:focus,
.reset-btn:active,
.export-btn:active {
  background-color: #fff;
  border-color: #356CEA;
  color: #356CEA;
}
.ant-select-selection--single {
  height: 34px;
}
.ant-select-selection--single .ant-select-selection__rendered {
  line-height: 32px;
}
.ant-calendar-picker,
.ant-calendar-picker-input,
.ant-input {
  height: 34px;
  border-radius: 4px;
  line-height: 32px;
}
.ant-input {
  border-radius: 4px;
  padding: 0 13px;
}
.ant-input:hover {
  border-color: #356CEA;
  border-right-width: 1px !important;
}
.ant-input:focus {
  box-shadow: none;
  border-color: #356CEA;
}
.ant-select-auto-complete.ant-select .ant-input:hover,
.ant-input-number:hover {
  border-color: #356CEA;
}
.ant-input-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
.ant-input-number {
  font-size: 12px;
  height: 34px;
}
.ant-popover-inner-content {
  border-radius: 4px;
  padding: 0;
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  border-right-color: #356CEA;
  border-bottom-color: #356CEA;
}
.ant-modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal-wrap .ant-modal {
  top: 0;
  padding-bottom: 0;
}
.ant-table-wrapper {
  min-height: 300px;
  background-color: #fff;
}
.ant-table-wrapper .red {
  color: #FD4949;
}
.ant-table-wrapper .ant-table-thead {
  height: 50px;
  border-radius: 6600px;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 0 20px;
  background: #EAF0FC;
  border: none;
  font-size: 13px;
  color: #444444;
  font-weight: 500;
  height: 50px;
}
.ant-table-wrapper .ant-table-placeholder {
  border-bottom: none;
}
.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 13px 20px;
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  line-height: 17px;
  word-break: break-all;
}
.ant-table-wrapper .ant-table-tbody > tr > td p {
  margin-bottom: 10px;
  line-height: 17px;
}
.ant-table-wrapper .ant-table-tbody > tr > td p:last-child {
  margin-bottom: 0px;
}
.ant-table-wrapper .ant-table-tbody > tr:nth-child(2n) {
  background: #FBFCFD;
}
.ant-table-wrapper .ant-table-tbody > tr:nth-child(2n-1) {
  background: #fff;
}
.ant-table-wrapper .ant-spin-nested-loading {
  min-height: 300px;
}
.ant-table-wrapper .ant-spin-dot {
  background-image: url('https://imagesize.hrwork.com/sp/files/a39a96cb-7a2a-41d8-8339-39768e6522a2.png');
  background-size: 60px 60px;
  width: 60px;
  height: 60px;
}
.ant-table-wrapper .ant-spin-dot i {
  display: none !important;
}
.ant-table-wrapper .ant-empty-normal .ant-empty-image {
  height: 180px;
  background-image: url('https://imagesize.hrwork.com/sp/files/a410d158-1abc-4363-8002-df3b99f380a8.png');
  background-size: 180px 180px;
  background-repeat: no-repeat;
  background-position: center;
}
.ant-table-wrapper .ant-empty-normal .ant-empty-image img {
  display: none;
}
.ant-select-dropdown {
  font-size: 12px;
}
.ant-select-selection:focus {
  border-color: #356CEA;
  box-shadow: none;
}
.ant-select-selection:hover {
  border-color: #356CEA;
  box-shadow: none;
}
.ant-select-selection:active {
  box-shadow: none;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: transparent;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #fff;
}
.ant-drawer-header {
  padding: 22px 20px 20px 20px;
  border: none;
}
.ant-drawer-title {
  font-size: 18px;
  font-weight: 400;
  color: #444;
  line-height: 28px;
}
.ant-form-item-required::before {
  display: none;
}
.big-outline-btn {
  width: 90px;
  height: 40px;
  padding: 9px 0;
  border: 1px solid #EEEEEE;
}
.big-outline-btn span {
  font-size: 16px;
  color: #666666;
  font-weight: 500;
  line-height: 22px;
}
.big-outline-btn:hover,
.big-outline-btn:focus {
  border: 1px solid #EEEEEE;
}
.big-outline-btn:hover span,
.big-outline-btn:focus span {
  color: #666666;
}
.ant-modal-confirm .ant-modal-close {
  display: block;
}
.comfirm-txt {
  font-size: 14px;
}
.all-blue-btn {
  background: #356CEA !important;
  border-color: #356CEA;
  display: flex;
  align-items: center;
  justify-content: center;
}
.all-blue-btn span {
  color: #fff;
}
.all-blue-btn:hover,
.all-blue-btn.active,
.all-blue-btn:focus {
  background: #356CEA !important;
  border-color: #356CEA;
}
.all-blue-btn img {
  margin-right: 10px;
}
.all-blue-btn[disabled] {
  border-color: #356CEA !important;
}
.modal-primary-btn {
  min-width: 70px;
  height: 34px;
  background: #356CEA;
  border-radius: 4px;
  border-color: #356CEA;
}
.modal-primary-btn span {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
  line-height: 22px;
}
.modal-primary-btn:hover,
.modal-primary-btn:focus {
  background: #356CEA;
}
.modal-primary-btn:hover span,
.modal-primary-btn:focus span {
  color: #FFFFFF;
}
.btn-colums .action-btn {
  margin-bottom: 10px;
}
.btn-colums .action-btn:last-child {
  margin-bottom: 0;
}
.back-list-btn {
  width: 80px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  line-height: 32px;
  font-size: 12px;
  color: #444444;
  font-weight: 500;
  text-align: center;
  box-shadow: none;
  cursor: pointer;
}
.back-list-btn:hover {
  border-color: #356CEA;
  color: #356CEA;
}
.ant-switch-checked {
  background-color: #356CEA;
}
.action-btn {
  height: 24px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 20px;
  padding: 0 9px;
  box-shadow: none;
  outline: none;
  display: flex;
  align-items: center;
}
.action-btn img {
  margin-right: 6px;
}
.action-btn:hover,
.action-btn:focus {
  background-color: #fff;
  border: 1px solid #EEEEEE;
  color: rgba(0, 0, 0, 0.65);
  box-shadow: none ;
}
.action-btn.tjdd {
  color: #356CEA;
  border-color: #356CEA;
}
.action-btn.tjdd:hover,
.action-btn.tjdd:focus {
  background-color: #fff;
  color: #356CEA;
  border-color: #356CEA;
}
.blue-action-btn {
  border-color: #356CEA;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blue-action-btn span {
  color: #356CEA;
}
.blue-action-btn:hover,
.blue-action-btn:focus {
  border-color: #356CEA;
}
.blue-action-btn img {
  margin-right: 6px;
}
.ant-modal-confirm .ant-modal-body {
  padding: 22px 20px 22px 20px;
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 18px;
  color: #444444;
  font-weight: 400;
  line-height: 28px;
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 10px;
  font-size: 12px;
  color: #999;
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  min-width: 70px;
  height: 34px;
  line-height: 32px;
  font-size: 14px;
  margin-left: 14px;
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn:hover {
  border-color: #356CEA;
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn:hover span {
  color: #356CEA;
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn.ant-btn-primary:hover {
  border-color: #356CEA;
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn.ant-btn-primary:hover span {
  color: #fff;
}
.ant-pagination {
  margin-top: 10px;
  text-align: right;
}
.ant-pagination .ant-pagination-total-text {
  font-size: 12px;
  color: #BEBFC0;
  font-weight: 400;
}
.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border-color: transparent;
  background-color: transparent;
}
.ant-pagination .ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination .ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-next:hover .ant-pagination-item-link {
  color: #356CEA;
}
.ant-pagination .ant-pagination-item:focus,
.ant-pagination .ant-pagination-item:hover {
  border-color: #356CEA;
}
.ant-pagination .ant-pagination-item:focus a,
.ant-pagination .ant-pagination-item:hover a {
  color: #356CEA;
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active {
  width: 30px;
  height: 30px;
  background: #356CEA;
  border-color: #356CEA;
  border-radius: 4px;
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active:hover a {
  color: #fff;
}
.ant-select-disabled .ant-select-selection,
.ant-input-disabled,
.ant-input-number-disabled {
  background: #F8FAFB;
  border: 1px solid #E9ECF0;
}
.ant-select-selection,
.ant-input,
.ant-input-number {
  border: 1px solid #E9ECF0;
}
.ant-form-explain {
  font-size: 10px;
}
.ant-modal-body {
  padding: 20px;
}
.detail-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.detail-top .title {
  font-size: 20px;
  color: #444444;
  font-weight: 600;
}
.detail-top button {
  width: 80px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
}
.detail-top button span {
  font-size: 12px;
  color: #444444;
  font-weight: 500;
}
.detail-top button:hover,
.detail-top button:active,
.detail-top button.focus {
  border: 1px solid #356cea;
}
.detail-top button:hover span,
.detail-top button:active span,
.detail-top button.focus span {
  color: #356cea;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #356CEA;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #356CEA;
  border-color: #356CEA;
}
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  box-shadow: none;
  border-color: #356CEA;
}
.ant-drawer-body {
  padding: 0px 20px 20px 20px;
}
.loading-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  animation: spin 1s linear infinite;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #666666;
}
.ant-modal-header {
  padding: 22px 20px 0 20px;
  border: none;
}
.ant-modal-header .ant-modal-title {
  font-size: 18px;
  color: #444444;
  font-weight: 400;
  line-height: 28px;
}
.ant-modal-close {
  width: 24px;
  height: 24px;
  background-image: url('https://imagesize.hrwork.com/sp/files/299cab47-9f99-4072-aed4-28b16ddb51e9.png');
  background-size: 24px 24px;
  margin: 22px 20px 0 0;
}
.ant-modal-close i {
  display: none;
}
.ant-modal-footer {
  border: none;
  padding: 0 20px 20px 20px;
}
.ant-modal-footer div {
  display: flex;
  justify-content: flex-end;
}
.ant-modal-footer .ant-btn {
  min-width: 70px;
  height: 34px;
  font-size: 14px;
  border-radius: 4px;
}
.ant-modal-footer .ant-btn:hover {
  border-color: #356CEA;
}
.ant-modal-footer .ant-btn:hover span {
  color: #356CEA;
}
.ant-modal-footer .ant-btn.ant-btn-primary {
  display: block;
  height: 34px;
  background: #356CEA;
  border-radius: 6px;
  border-color: #356CEA;
}
.ant-modal-footer .ant-btn.ant-btn-primary:hover {
  background: #356CEA;
  border-color: #356CEA;
}
.ant-modal-footer .ant-btn.ant-btn-primary:hover span {
  color: #FFFFFF;
}
.ant-modal-footer .ant-btn.ant-btn-primary span {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
}
.sub-list .ant-tabs {
  height: 40px;
}
.sub-list .ant-tabs-bar {
  border: none;
  margin: 0;
}
.sub-list .ant-tabs-tab {
  width: 100px;
  height: 40px;
  text-align: center;
  margin: 0;
  font-size: 14px;
  color: #444444;
  font-weight: 500;
  padding: 0 ;
  line-height: 40px;
}
.sub-list .ant-tabs-tab:hover {
  color: #444444;
}
.sub-list .ant-tabs-nav .ant-tabs-tab-active {
  background: #FFFFFF;
  border-radius: 6px 6px 0 0;
  font-size: 14px;
  color: #356CEA;
  font-weight: 500;
}
.sub-list .ant-tabs-ink-bar {
  width: 20px !important;
  height: 3px;
  background: #356CEA;
  margin-left: 40px;
}
.sub-list .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.time-box {
  display: flex;
}
.time-box .txt-box {
  width: 30px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #CCCCCC;
}
.time-box .txt-box .mm {
  margin: 10px 0;
}
.time-box .txt-box .active {
  color: #356CEA;
}
.time-box .img-box {
  width: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 5px ;
}
.time-box .img-box .blue-ball {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-image: url('https://imagesize.hrwork.com/sp/files/f98fb521-04ab-4185-9e72-acf87c6ee100.png');
  background-size: 6px 6px;
}
.time-box .img-box .grey-ball {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-image: url('https://imagesize.hrwork.com/sp/files/9f92e691-3775-4093-b110-a44e78d31330.png');
  background-size: 4px 4px;
}
.time-box .img-box .blue-line {
  width: 5px;
  height: 25px;
  background-image: url('https://imagesize.hrwork.com/sp/files/5274d03d-7cc8-499a-819f-686fd3db72b8.png');
  background-size: 2px 25px;
  background-position: center;
  background-repeat: no-repeat;
}
.time-box .img-box .grey-line {
  width: 5px;
  height: 25px;
  background-image: url('https://imagesize.hrwork.com/sp/files/6045c827-b17a-4a92-be15-a7ba7e32e18e.png');
  background-size: 2px 25px;
  background-position: center;
  background-repeat: no-repeat;
}
.handle-scroll,
.ant-table-body {
  overflow: scroll;
  overflow: overlay !important;
}
@media screen and (max-width: 1430px) {
  .search-box .sb-lbs p {
    height: 0;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
