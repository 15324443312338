.ticket-app {
  height: 100%;
}
.ticket-app .ticket-money-box {
  position: absolute;
  right: 0;
  bottom: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.ticket-app .ticket-money-box .txt {
  color: #444444;
}
.ticket-app .ticket-money-box .value {
  color: #356CEA;
  font-weight: 500;
}
.ticket-app .ticket-money-box button {
  width: 100px;
  height: 34px;
  padding: 0;
  border-radius: 4px;
  margin-left: 20px;
}
.ticket-app .ticket-money-box button img {
  margin-right: 10px;
}
.showtip-page {
  padding-top: 40px;
}
.application-warm .ant-modal-confirm-body {
  font-size: 20px;
  color: #444444;
  font-weight: 600;
}
.application-warm .ant-modal-confirm-body i {
  float: none;
  display: inline-block;
}
.application-warm .ant-modal-confirm-content .txt {
  color: #356CEA;
}
.tip-font-box .tip-font {
  font-weight: 1000;
  font-size: 16px;
}
.tip-error-refund {
  color: red;
  font-size: 12px;
  white-space: nowrap;
  border: solid 1px;
  line-height: 1.1;
  border-radius: 3px;
}
.invoicing_amount {
  display: flex;
  align-items: center;
}
.invoicing_amount .txt {
  margin-right: 8px;
  width: 80px;
}
.invoicing_amount .refund-tip {
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0;
  font-size: 12px;
  color: #FD4949;
  background: #EAF0FC;
  box-shadow: none;
  position: relative;
  border-radius: 4px;
}
.invoicing_amount .refund-tip::before {
  content: '';
  width: 6px;
  height: 6px;
  background-color: #EAF0FC;
  position: absolute;
  left: -3px;
  top: 12px;
  transform: rotate(45deg);
}
