.rengwupici {
  width: 100%;
}
.rengwupici .search-form {
  flex-wrap: wrap;
}
.rengwupici .search-form .ant-form-item {
  display: flex;
  flex-direction: column;
  margin-right: 14px;
}
.rengwupici .search-form .ant-form-item .ant-form-item-label {
  text-align: left;
  line-height: 17px;
  margin-bottom: 10px;
}
.rengwupici .search-form .ant-form-item .ant-form-item-label label {
  color: #444444;
}
.rengwupici .search-form .ant-form-item .ant-form-item-control-wrapper,
.rengwupici .search-form .ant-form-item .ant-form-item-control {
  line-height: 34px;
  height: 34px;
}
.rengwupici .search-form .ant-form-item .ant-input,
.rengwupici .search-form .ant-form-item .ant-select-selection {
  border: 1px solid #ECEEF7;
  height: 34px;
  line-height: 32px;
}
.rengwupici .search-form .ant-form-item .ant-input:hover,
.rengwupici .search-form .ant-form-item .ant-select-selection:hover,
.rengwupici .search-form .ant-form-item .ant-input.active,
.rengwupici .search-form .ant-form-item .ant-select-selection.active,
.rengwupici .search-form .ant-form-item .ant-input:focus,
.rengwupici .search-form .ant-form-item .ant-select-selection:focus {
  border: 1px solid #356CEA;
  box-shadow: none ;
}
.rengwupici .search-box {
  margin-bottom: 0;
  padding: 20px 20px 0 20px;
  background-color: #fff;
  position: relative;
}
.rengwupici .search-box.no-whitebg {
  background-color: transparent;
  padding-top: 0;
  padding-left: 0;
}
.rengwupici .search-box.no-whitebg .search-form {
  padding-top: 0;
}
.rengwupici .search-box .search-form .ant-form-item .ant-form-item-label {
  margin-bottom: 0;
}
.rengwupici .search-box .back-list-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  border: 1px solid #EEEEEE;
}
.rengwupici .search-box .back-list-btn:hover,
.rengwupici .search-box .back-list-btn:active,
.rengwupici .search-box .back-list-btn.focus {
  border: 1px solid #356cea;
}
.rengwupici .search-box .back-list-btn:hover span,
.rengwupici .search-box .back-list-btn:active span,
.rengwupici .search-box .back-list-btn.focus span {
  color: #356cea;
}
.to-pay-modal .ant-modal-body {
  padding: 10px 20px 20px 20px;
}
.to-pay-modal .ant-modal-body p {
  color: #999;
  font-size: 12px ;
}
.to-pay-modal .ant-modal-footer .ant-btn {
  width: 70px;
  height: 34px;
  margin-left: 14px;
  display: block;
  border-radius: 4px;
}
.to-pay-modal .ant-modal-footer .ant-btn span {
  font-size: 14px;
}
