.roster-add-people .ant-row {
  display: flex;
  flex-direction: column;
}
.roster-add-people .ant-form-item-required::before {
  display: none;
}
.roster-add-people .ant-form-item-label {
  text-align: left;
  line-height: 17px;
  margin-bottom: 10px;
}
.roster-add-people .ant-form-item-label label {
  color: #666666;
}
.roster-add-people .ant-form-item-control-wrapper {
  min-height: 34px;
}
.roster-add-people .ant-form-item-control-wrapper .ant-form-item-control {
  line-height: 32px;
}
.roster-add-people .ant-form-item-control-wrapper input {
  width: 460px;
}
.roster-add-people .ant-form-item-control-wrapper .ant-select-selection,
.roster-add-people .ant-form-item-control-wrapper .ant-select-selection__rendered {
  width: 460px;
}
.roster-add-people .add-btn {
  width: 70px;
  height: 34px;
  background: #356CEA;
  border-radius: 6px;
  box-shadow: none;
  border-color: #356CEA;
}
.roster-add-people .add-btn span {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
}
