.drawer-box-detail .ant-modal-body {
  padding: 10px 20px 24px 20px;
}
.drawer-box-detail .c-box {
  width: 460px;
  background: #F8FAFB;
  border-radius: 4px;
  padding: 20px;
}
.drawer-box-detail .c-box .item-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}
.drawer-box-detail .c-box .item-box .item-title {
  font-size: 14px;
  color: #444444;
  font-weight: 500;
}
.drawer-box-detail .c-box .item-box .item-content {
  font-size: 14px;
  color: #444444;
}
.drawer-box-detail .c-box .item-box:last-child {
  margin-bottom: 0;
}
.drawer-box-detail .second_title {
  margin-bottom: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
}
.drawer-box-detail .item-box {
  position: relative;
  display: flex;
  margin-bottom: 12px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
.drawer-box-detail .item-box .item-title {
  flex-shrink: 0;
  width: 104px;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
  font-weight: bold;
}
.drawer-box-detail .item-box .item-img {
  width: 100px;
  height: 100px;
}
.drawer-box-detail .item-box .look_bigimg {
  position: absolute;
  top: 0;
  left: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.4);
}
.drawer-box-detail .item-box .look_bigimg .change_big {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.drawer-box-detail .item-box .plan {
  margin-top: 4px;
  padding: 10px;
  border-radius: 3px;
  background: rgba(78, 78, 78, 0.03);
}
.drawer-box-detail .item-box .margin {
  margin-top: 10px;
}
.drawer-box-detail .item-box .gnqx {
  text-align: right;
}
.drawer-box-detail .item-box .func-name {
  margin: 0 0 10px 10px;
}
.drawer-box-detail .divider {
  margin: 0 0 12px;
}
.bigModel {
  text-align: center;
}
