.trade-order .search-box {
  background-color: #fff;
  position: relative;
  padding: 20px 20px 0 20px;
  margin: 0;
}
.trade-order .search-box .ant-calendar-picker,
.trade-order .search-box .ant-calendar-picker-input,
.trade-order .search-box .ant-input {
  border-color: #ECEEF7;
}
.trade-order .search-box .ant-calendar-picker:hover,
.trade-order .search-box .ant-calendar-picker-input:hover,
.trade-order .search-box .ant-input:hover {
  border-color: #356CEA;
  border-right-width: 1px !important;
}
.trade-order .search-box .ant-select-selection {
  border: 1px solid #ECEEF7;
}
.trade-order .search-box .ant-select-selection:hover {
  border-color: #356CEA;
  border-right-width: 1px !important;
}
.trade-order .data-info {
  position: relative;
}
.trade-order .step-list {
  margin-top: 20px;
}
.trade-order .step-list .step {
  width: 700px;
}
.trade-order .tab-list {
  margin-top: 20px;
  font-size: 12px;
}
.trade-order .tab-list .tab-item {
  border: 1px solid #dadada;
  margin-top: 20px;
  position: relative;
}
.trade-order .tab-list .tab-item .img-pos {
  position: absolute;
  right: 2px;
  top: 29px;
  width: 67px;
  height: 67px;
}
.trade-order .tab-list .tab-item .name {
  background: rgba(31, 56, 88, 0.06);
  padding: 10px 0;
}
.trade-order .tab-list .tab-item .name div {
  text-align: center;
}
.trade-order .tab-list .tab-item .info > div {
  text-align: center;
  border-right: 1px solid #dadada;
  padding: 10px;
  font-size: 12px;
}
.trade-order .tab-list .tab-item .info > div:last-child {
  border-right: 0px;
}
.trade-order .tab-list .tab-item .info > div > p {
  word-break: break-all;
  text-align: left;
}
.trade-order .tab-list .tab-item .info > div .text {
  padding: 10px;
  background: rgba(31, 56, 88, 0.06);
}
.trade-order .tab-list .tab-item .info > div .text > p {
  text-align: left;
  margin-bottom: 7px;
}
.trade-order .tab-list .tab-item .info > div .text > p:last-child {
  margin-bottom: 2px;
}
.trade-order .tab-list .tab-item .info > div .key {
  display: flex;
  align-items: center;
  width: 155px;
}
.trade-order .tab-list .tab-item .info > div .key .promitIng {
  margin-left: 3px;
  height: 16px;
  width: 16px;
}
.trade-order .tip-info {
  font-size: 12px;
  text-align: left;
  margin: 4px 0;
}
.trade-order .tip-info i {
  width: 16px;
  margin-top: 2px;
  margin-right: 3px;
  height: 16px;
  background: url("https://imagesize.hrwork.com/sp/files/b760f7ad-ec24-4683-a452-94acd3d5fa11.png") no-repeat center center;
  background-size: 100% 100%;
}
.trade-order .yue_flex {
  display: flex;
}
.trade-order .yue_flex .yue_item {
  height: 72px;
}
.trade-order .yue_flex .yue_divider {
  margin: 8px 35px 0;
  height: 44px;
}
.switch-box {
  position: absolute;
  right: 20px;
  bottom: 27px;
  height: 20px;
}
.switch-box .ant-switch {
  height: 20px;
}
.switch-box .ant-switch .ant-switch-inner {
  height: 20px;
}
.switch-box .ant-switch::after {
  width: 16px;
  height: 16px;
  border-radius: 16px;
}
.switch-box span {
  font-size: 12px;
  color: #444444;
  font-weight: 400;
  line-height: 22px;
  height: 22px;
  margin-right: 10px;
}
@media screen and (min-width: 375px) and (max-width: 1600px) {
  .trade-order .search-box.sm-screen .switch-box {
    display: none;
  }
  .trade-order .ant-pagination {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .trade-order .ant-pagination .ant-pagination-total-text {
    flex: 1;
  }
  .trade-order .ant-pagination .ant-pagination-total-text .btxq {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  .trade-order .ant-pagination .ant-pagination-total-text .btxq .switch-box {
    position: static;
  }
}
