.info-box .child-list {
  width: 1000px;
}
.brief > div {
  margin-bottom: 10px;
}
.brief > div p {
  width: 260px;
}
.brief > div p span {
  margin-left: 20px;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: none;
  }
}
