.up-business-box {
  min-height: 117px;
  background: #f8fafb;
  border-radius: 4px;
  padding: 14px 58px 14px 14px;
}
.up-business-box .tips {
  font-size: 12px;
  color: #444444;
  line-height: 18px;
  padding-bottom: 14px;
  display: inline-block;
}
.up-business-box .ubb-btn {
  width: 80px;
  border-radius: 4px;
  border: 1px solid #356cea;
  font-size: 12px;
  font-weight: 600;
  color: #356cea;
  margin-right: 10px;
}
.up-business-del-box {
  padding: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8fafb;
}
.up-business-del-box .upbd-box {
  padding-left: 20px;
  display: inline-block;
}
.up-business-del-box .upbd-box .title {
  display: block;
}
.up-business-del-box .upbd-box .size {
  display: block;
}
.up-business-del-box .imgicon {
  width: 35px;
  height: 40px;
  display: inline-block;
}
