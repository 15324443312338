.plat_rule .blue {
  color: #1f3858;
  font-size: 12px;
}
.plat_rule .search_box {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.plat_rule .card_box {
  border: 1px solid #bbc3cc;
  border-radius: 3px;
}
.plat_rule .card_title {
  line-height: 30px;
  font-size: 12px;
  color: #1f3858;
  background: #eff1f3;
}
.plat_rule .card_title span {
  margin-left: 10px;
}
.plat_rule .card_con {
  height: 160px;
  padding-left: 20px;
}
.plat_rule .card_bottom {
  height: 50px;
  background: #eff1f3;
  border-top: 1px solid #bbc3cc;
  display: flex;
  align-content: center;
}
