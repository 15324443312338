.qrcodeContainer {
  height: 120px;
  width: 120px;
  transform: translateX(-50%);
  position: relative;
  left: 50%;
  top: 5px;
}
.qrcodeContainer img {
  width: 100%;
  height: 100%;
}
.btn-container-qr {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.btn-container-qr > * {
  margin-right: 10px;
}
.modal-qrCodeForKaigong .ant-modal-body {
  padding: 0;
}
.modal-qrCodeForKaigong .c-box {
  display: flex;
}
.modal-qrCodeForKaigong .ll {
  width: 600px;
  height: 420px;
  background: #F8FAFB;
  border-radius: 4px 0 0 4px;
}
.modal-qrCodeForKaigong .ll .txt-box {
  width: 524px;
  height: 324px;
  border: 2px solid #356CEA;
  position: relative;
  margin: 38px 38px 0 38px;
}
.modal-qrCodeForKaigong .ll .txt-box .left-box {
  width: 324px;
  padding-left: 20px;
  position: relative;
}
.modal-qrCodeForKaigong .ll .txt-box .left-box::before {
  content: '';
  width: 1px;
  height: 160px;
  background-color: #0874FF;
  position: absolute;
  top: 30px;
  right: 0;
}
.modal-qrCodeForKaigong .ll .txt-box .left-box::after {
  content: '';
  width: 1px;
  height: 220px;
  background-color: #0874FF;
  position: absolute;
  top: 0;
  right: -3px;
}
.modal-qrCodeForKaigong .ll .txt-box .right-box {
  width: 199px;
  position: relative;
}
.modal-qrCodeForKaigong .ll .txt-box .right-box::before {
  content: '';
  width: 1px;
  height: 160px;
  background-color: #0874FF;
  position: absolute;
  top: 30px;
  left: 5px;
}
.modal-qrCodeForKaigong .ll .txt-box .right-box .qrcodeContainer {
  margin: 20px 0;
}
.modal-qrCodeForKaigong .ll .txt-box .right-box .p1 {
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
}
.modal-qrCodeForKaigong .ll .txt-box .aaa {
  width: 408px;
  font-size: 24px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 33px;
  text-align: center;
  margin: 30px 58px 20px 58px;
}
.modal-qrCodeForKaigong .ll .txt-box .img1 {
  position: absolute;
  left: -8px;
  top: -8px;
}
.modal-qrCodeForKaigong .ll .txt-box .img2 {
  position: absolute;
  right: -8px;
  bottom: -8px;
}
.modal-qrCodeForKaigong .ll .txt-box .txt1 {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}
.modal-qrCodeForKaigong .ll .txt-box .txt1 p {
  color: #999999;
  letter-spacing: 0;
  font-weight: 600;
  margin-bottom: 10px;
}
.modal-qrCodeForKaigong .ll .txt-box .txt1 p .bolder {
  color: #333333;
  margin-left: 10px;
}
.modal-qrCodeForKaigong .ll .txt-box .txt2 {
  display: flex;
  height: 140px;
  align-items: center;
  margin-top: 31px;
}
.modal-qrCodeForKaigong .ll .txt-box .txt2 .a {
  width: 144px;
  height: 75px;
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  line-height: 25px;
}
.modal-qrCodeForKaigong .ll .bb-txt {
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  margin-top: 24px;
  text-align: center;
}
.modal-qrCodeForKaigong .rr {
  width: 300px;
  height: 420px;
  padding-left: 30px;
  padding-top: 22px;
}
.modal-qrCodeForKaigong .rr .tt {
  font-size: 20px;
  color: #444444;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 25px;
}
.modal-qrCodeForKaigong .rr p {
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  line-height: 40px;
}
.modal-qrCodeForKaigong .rr .select-time {
  margin-top: 20px;
}
.modal-qrCodeForKaigong .rr .select-time .t {
  font-size: 14px;
  color: #AAAAAA;
  line-height: 20px;
}
.modal-qrCodeForKaigong .rr .select-time .ant-select {
  margin-top: 10px;
}
.modal-qrCodeForKaigong .rr .big-lbs {
  width: 240px;
  height: 50px;
  border-radius: 6px;
  margin-top: 51px;
}
.modal-qrCodeForKaigong .rr .big-lbs span {
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 500;
}
