.search-info p {
  font-size: 12px;
  height: 27px;
}
.search-info .left-ds {
  margin-left: 20px;
}
.search-info .right-ds {
  margin-right: 14px;
}
.info-content {
  width: 100%;
  margin-top: 28px;
}
.opacity {
  color: #5B70F2;
  font-size: 12px;
}
.opacity .split {
  margin: 0 18px;
}
.info-box {
  width: 100%;
  background: #fff;
}
.info-title {
  height: 81px;
  font-size: 20px;
  padding-left: 30px;
  background: #fff;
  color: #232425;
  line-height: 81px;
}
.pagination {
  width: 100%;
  padding-right: 30px;
  margin-top: 30px;
}
.total-num {
  font-size: 14px;
  color: #606266;
  margin-right: 30px;
}
.blue {
  color: #4090e2;
}
.data-info {
  width: 100%;
}
.ar {
  border: solid 1px #aaa;
  border-bottom-width: 0;
  border-left-width: 0;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ty-message-pos {
  width: 360px;
  height: 330px;
  background: #FFFFFF;
  border: 1px solid #ECEEF7;
  border-radius: 3px;
  z-index: 100;
  position: absolute;
  top: 20px;
  left: 50% ;
  margin-left: -180px;
}
.ty-arrow {
  width: 10px;
  height: 10px;
  z-index: 101;
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: -5px;
  transform: rotate(45deg);
}
.ty-company-arrow {
  border-top: 1px solid #ECEEF7;
  border-left: 1px solid #ECEEF7;
  background: #fff;
  z-index: -9;
}
.ty-message-arrow {
  border-top: 1px solid #ECEEF7;
  border-left: 1px solid #ECEEF7;
  background: #ECEEF7;
}
.ty-message-show,
.ty-company-show {
  display: block;
}
.ty-message-hide,
.ty-company-hide {
  display: none;
}
.system-levelup {
  background: #fff;
  height: 100%;
  font-size: 30px;
  text-align: center;
  padding-top: 250px;
}
.system-levelup p {
  font-size: 14px;
  color: #999;
}
.ty-company-pos {
  width: 240px;
  max-height: 206px;
  background: #FFFFFF;
  border: 1px solid #ECEEF7;
  z-index: 100;
  position: absolute;
  top: 20px;
  left: 50% ;
  margin-left: -120px;
}
.ty-company-pos .c-box {
  max-height: 200px;
  padding: 10px 0;
  overflow: auto;
}
.ty-company-pos .c-box .item-box {
  width: 240px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  color: #444444;
}
.ty-company-pos .c-box .item-box.active {
  background: #EAF0FC;
  color: #356CEA;
}
