.qrcode-list.hide {
  display: none;
}
.top-qrcode-roster {
  position: relative;
  background: #fff;
}
.top-qrcode-roster .create-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background-image: url('https://imagesize.hrwork.com/sp/files/fe66e86e-6fcb-4bec-97b9-0288f86da0dc.png');
  width: 130px;
  height: 34px;
  border-radius: 6px;
  background-size: 130px 34px;
  box-shadow: none;
  border: none;
}
.top-qrcode-roster .sub-list-search-box {
  width: 90%;
}
.addform-qr-form .ant-form-item {
  margin-bottom: 14px;
}
.addform-qr-form .ant-form-item-label {
  line-height: 17px;
  margin-bottom: 10px;
}
.addform-qr-form .ant-form-item-label label {
  color: #666;
}
.addform-qr-form .ant-form-item-control {
  line-height: 34px;
}
.addform-qr-form .ant-form-item-children {
  line-height: 16px;
  display: flex;
  flex-wrap: wrap;
}
.addform-qr-form .ant-form-item-children label {
  width: 120px;
  margin: 0 0 18px 0;
}
.footer-addForm-qrcode {
  background: #F8FAFB;
  padding: 15px;
  margin: 15px -20px -23px;
  font-size: 12px;
  padding: 15px 23px;
  border-radius: 0 0 4px 4px;
  display: flex;
  align-items: center;
}
.footer-addForm-qrcode ol {
  padding-right: 15px;
  padding-left: 15px;
}
.footer-addForm-qrcode ol li {
  list-style-type: circle;
}
.del-rosterQrcode-modal .title-rosterQrcode {
  font-size: 18px;
  color: #444444;
  font-weight: 400;
  line-height: 28px;
}
.del-rosterQrcode-modal .content-rosterQrcode {
  margin-top: 10px;
  font-size: 12px;
  color: #999;
}
.del-rosterQrcode-modal .footer-rosterQrcode {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
.del-rosterQrcode-modal .footer-rosterQrcode button {
  width: 110px;
  height: 34px;
  margin-left: 14px;
  font-size: 14px;
}
.del-rosterQrcode-modal .footer-rosterQrcode .reset-btn {
  width: 120px;
}
.qrCodeModal {
  display: flex;
}
.qrCodeModal .left {
  background-color: white;
  border-radius: 4px;
  width: 260px;
  flex-shrink: 0;
  text-align: center;
  margin-right: 40px;
  border: solid 1px #ECEEF7;
}
.qrCodeModal .left .company {
  font-size: 14px;
  font-weight: bolder;
  padding: 10px 0;
  padding-top: 20px;
}
.qrCodeModal .left .deadline {
  font-size: 12px;
  margin-bottom: 15px;
}
.qrCodeModal .left .deadline span {
  color: #356CEA;
}
.qrCodeModal .left .imgContainer-outer {
  height: 270px;
  background-repeat: no-repeat;
  background-image: url(https://imagesize.hrwork.com/sp/files/fa995e45-fa4a-43b3-b13d-9245b2a20c23.png);
  padding-top: 43px;
}
.qrCodeModal .left .imgContainer-outer .imgContainer-inner {
  padding: 5px;
  border-radius: 4px;
}
.qrCodeModal .left .imgContainer-outer .imgContainer-inner img {
  width: 150px;
  height: 150px;
}
.qrCodeModal .left .imgContainer-outer .tip {
  font-size: 12px;
  color: white;
  margin-top: 10px;
}
.qrCodeModal .right {
  line-height: 1.6;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
.qrCodeModal .right .link-a {
  color: #356CEA;
  text-decoration: solid;
  text-decoration-line: underline;
  cursor: pointer;
  font-weight: bolder;
}
.qrCodeModal .right .info {
  flex: 1;
  width: 100%;
}
.qrCodeModal .right .info > p + p {
  margin-top: 10px;
}
.qrCodeModal .right .btn-confirm-container {
  display: flex;
  justify-content: center;
}
.qrCodeModal .right .btn-confirm-container button {
  background-color: #356CEA;
}
.qrCodeModal .right .btn-confirm-container button.disabled {
  background-color: #bbb;
  color: white;
  cursor: not-alloweds;
}
.disabled-btn-line {
  color: #bbb;
  cursor: not-allowed !important;
}
.opacity2 {
  opacity: 0.2;
}
.flex-x .action-btn {
  margin-right: 10px;
}
.collect-status {
  display: flex;
  align-items: center;
}
.collect-status img {
  margin-right: 6px;
}
.collect-status .green {
  color: #57CA7E;
}
.btn-confirm-qrcode {
  width: 240px;
  height: 50px;
  font-size: 18px;
}
.h5-fileds-checkbox .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #AAAAAA;
  border-color: #AAAAAA !important;
}
@media screen and (max-width: 1440px) {
  .search-box .sm-btns .ant-form-item-label {
    line-height: 10px;
  }
}
