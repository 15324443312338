.taskForm .ant-form-item-required::before {
  display: none;
}
.taskForm .ant-form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}
.taskForm .ant-form-item .ant-form-item-label {
  margin-bottom: 10px;
  height: 17px;
  line-height: 17px;
  text-align: left;
}
.taskForm .ant-form-item .ant-form-item-label label {
  color: #666666;
}
.taskForm .ant-form-item-control {
  line-height: 34px;
}
.taskForm .status-box {
  width: 460px;
  height: 40px;
  background: #F8FAFB;
  border-radius: 4px;
  padding: 11px 14px;
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}
.taskForm .status-box img {
  margin-right: 4px;
}
.taskForm .status-box .txt1 {
  font-size: 12px;
  color: #FF5C5C;
}
.taskForm .status-box .txt2 {
  font-size: 12px;
  color: #AAAAAA;
}
.taskForm .status-box .line {
  width: 1px;
  height: 16px;
  border: 1px solid #E9ECF0;
  display: inline-block;
  margin: 0 13.5px;
}
.taskAddItem-flex {
  display: flex;
  align-items: center;
}
.taskAddItem-flex .ant-form-item {
  margin-bottom: 0;
}
.subtible {
  padding-left: 10px;
  margin: 0 0 14px 0;
  font-size: 14px;
  color: #444444;
  font-weight: 400;
  position: relative;
  line-height: 20px;
}
.subtible::before {
  content: '';
  width: 3px;
  height: 14px;
  background: #356CEA;
  border-radius: 15px;
  position: absolute;
  left: 0;
  top: 3px;
}
